/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import _isEmpty from 'lodash/isEmpty';
// import _merge from 'lodash/merge';
// import _size from 'lodash/size';
// import _isEqual from 'lodash/isEqual';

import AppWrapper from "../../components/AppWrapper";
import Personnels from "./table";

// import { cloneCollections } from '../../helpers/data';

import { getPersonnels } from "../../actions/personnels";
import { getRegions } from "../../actions/m_regions";
import { getRoles } from "../../actions/m_roles";
import { resetRedux } from "../../actions/misc";

import { PLATFORM_ADMIN_DEFAULT_REGION } from "../../constants";

class PersonnelsPage extends React.Component {
    state = {
        // query: {
        //     searchterms: '',
        //     filterBy: 'all',
        //     sortBy: 'modified_on-desc',
        //     perPage: 20,
        //     page: 1
        // },
        randNum: false,
    };

    componentDidMount() {
        const { partner_id } = this.props.match.params;
        const { regionsList, rolesList } = this.props;
        // get personnels
        if (partner_id === PLATFORM_ADMIN_DEFAULT_REGION) {
            this.props.dispatch(getPersonnels({ region: PLATFORM_ADMIN_DEFAULT_REGION }));
        } else {
            this.props.dispatch(getPersonnels({ partner_id }));
        }

        // get regions
        if (!regionsList) {
            this.props.dispatch(getRegions());
        }
        // get roles
        if (!rolesList) {
            this.props.dispatch(getRoles());
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { personnelsList, regionsList, rolesList, randNum } = this.props;
        if (personnelsList && regionsList && rolesList && randNum && randNum !== this.state.randNum) {
            this.setState({ randNum });
        } // end - randNum
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux("personnels"));
    }

    handleRefresh = (props) => {
        const { authData } = this.props;
        // get personnels
        this.setState({ randNum: false });
        this.props.dispatch(resetRedux("personnels"));
        this.props.dispatch(getPersonnels(props));
    };

    handlePageChange = (partner_id) => {
        const { authData } = this.props;
        // get personnels
        this.setState({ randNum: false });
        this.props.dispatch(resetRedux("personnels"));
        this.props.history.push("/partner/" + partner_id + "/personnel");
        if (partner_id === PLATFORM_ADMIN_DEFAULT_REGION) {
            this.props.dispatch(getPersonnels({ region: PLATFORM_ADMIN_DEFAULT_REGION }));
        } else {
            this.props.dispatch(getPersonnels({ partner_id }));
        }
    };

    renderContents() {
        const { partner_id } = this.props.match.params;
        const { authData, personnelsList, regionsList, rolesList } = this.props;
        // const { query } = this.state;
        return (
            <Personnels
                partner_id={partner_id}
                personnels={personnelsList}
                regions={regionsList}
                roles={rolesList}
                authData={authData}
                onRefresh={this.handleRefresh}
                onPageChange={this.handlePageChange}
            />
        );
    }

    render() {
        const { partner_id } = this.props.match.params;
        const { randNum } = this.state;
        const { personnelsList, authData } = this.props;
        return (
            <AppWrapper
                title="SMS Users"
                subtitle="Setup"
                back={"/partner/" + partner_id}
                breadcrumbs={[{ url: "/partner/" + partner_id, label: "Edit Partner" }, { label: "SMS Users" }]}
                buttons={[
                    { url: "/partner/" + partner_id, label: "Partner Info", icon: "fa fa-cog" },
                    { url: "/partner/" + partner_id + "/personnel", label: "SMS Users", icon: "fa fa-users", active: true },
                ]}
                onLoad={!(personnelsList && randNum) ? true : false}
                contents={authData ? this.renderContents() : null}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: (state.auth && state.auth.user) || null,
        personnelsList: (state.personnels && state.personnels.personnels) || null,
        randNum: (state.personnels && state.personnels.rand) || null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        rolesList: state.maintenance && state.maintenance.roles ? state.maintenance.roles : false,
    };
};

export default compose(connect(mapStateToProps), withRouter)(PersonnelsPage);

/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
// import _merge from 'lodash/merge';
// import _size from 'lodash/size';
// import _isEqual from 'lodash/isEqual';

import AppWrapper from '../../components/AppWrapper';
import ScheduleSwitcher from '../../components/ScheduleSwitcher';
import Table from './table';

import { isAdmin, getUserRegionFilter } from '../../helpers/auth';
// import { cloneCollections } from '../../helpers/data';

import { getMentors } from '../../actions/mentors';
import { getRegions } from '../../actions/m_regions';
import { getSchedulesOptions } from '../../actions/schedules';
// import { getMaintenance } from '../../actions/maintenance';
import { resetRedux } from '../../actions/misc';

import { ButtonGroup, AInfoLink } from '../../styles/button';
import { InfoBox } from '../../styles/message';

const TableWrapper = styled.div`
    margin-top: 30px;
    padding-top: 15px;
    border-top: 1px solid #ddd;
`;

class DatabaseMentorsPage extends React.Component {

    state = {
        dataLoaded: false
    };

    componentDidMount() {
        const { authData, mentorsList, schedulesList, regionsList } = this.props;

        // get mentors
        this.props.dispatch(getMentors({ region: getUserRegionFilter(authData) }));

        // get schedule options
        if ( !schedulesList )
            this.props.dispatch(getSchedulesOptions({ region: getUserRegionFilter(authData) }));

        // get regions
        if ( !regionsList )
            this.props.dispatch(getRegions());

        if ( regionsList && mentorsList ) {
            this.setState({ dataLoaded: true });
        } // end - randNum
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { mentorsList, regionsList, schedulesList } = this.props;
        if ( mentorsList && regionsList && schedulesList && !this.state.dataLoaded ) {
            this.setState({ dataLoaded: true });
        } // end - randNum
    }

    renderTable() {
        const { authData, mentorsList, regionsList, schedulesList } = this.props;
        return <Table
                mentors={mentorsList}
                regions={regionsList}
                schedules={schedulesList}
                authData={authData} />;
    }

    renderContents() {
        const { mentorsList } = this.props;
        return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                    <InfoBox>
                        <i className="fa fa-commenting-o"></i><strong style={{ fontWeight: '700' }}>Add your mentors here:</strong><br />
                        1. Each mentor should have 2 logins to LMS<br />
                        <span style={{ width: '50px', visibility: 'hidden' }}>a</span>a. One for the student role (e.g. haslina+student@gmail.com)<br />  
                        <span style={{ width: '50px', visibility: 'hidden' }}>a</span>b. One for the mentor role (e.g. haslina+mentor@gmail.com)<br /> 
                        2. Student Role on LMS <br />
                        <span style={{ width: '50px', visibility: 'hidden' }}>a</span>- Mainly used for training <br />
                        <span style={{ width: '50px', visibility: 'hidden' }}>a</span>- A Mentor needs to earn the Mentor Startup’s Digital Badge to become an eligible mentor<br />
                        <span style={{ width: '50px', visibility: 'hidden' }}>a</span>- A principal will <a href="https://sms.chumbaka.asia/schedules/new" target="_blank">schedule the LMS-Mentor Development Program</a> to onboard the Mentors. In this Program, <a href ="https://sms.chumbaka.asia/course_access" target="_blank">add the mentors as students</a> <br />
                        <span style={{ width: '50px', visibility: 'hidden' }}>a</span>- After the MDP, Mentor can also use this account for testing <br />
                        3. Mentor Role on LMS <br />
                        <span style={{ width: '50px', visibility: 'hidden' }}>a</span>- Add Mentors here with their mentor-role email <br />
                        <span style={{ width: '50px', visibility: 'hidden' }}>a</span>- To experience as a student in LMS, each mentor can use the “Student View” function <br /><br />

                        <i className="fa fa-commenting-o"></i><strong style={{ fontWeight: '700' }}>Use a different email for LMS & SMS.</strong><br />
                        If you are a principal and/or a school coordinator, you need to use both SMS & LMS. Due to security reasons, we have disabled the linkages between SMS and LMS for the same account. <br /><br />
                        To minimise this confusion, we suggest to follow the following format:<br />
                        For SMS - haslina@gmail.com<br />
                        For LMS - haslina+mentor@gmail.com<br /><br />
                        Note: <br />
                        1. the plus (“+”) sign method is recognised as a different email in LMS, <a href="https://support.google.com/a/users/answer/9308648?hl=en#:~:text=If%20you%20don't%20want,of%20emails%20for%20different%20purposes." target="_blank">you will still get your email</a> in your inbox<br />
                        2. only available for those with gmail / on gsuite
                    </InfoBox>
                </Grid>
                <Grid item xs={12} sm={4}> </Grid>
            </Grid>
            <TableWrapper>{this.renderTable()}</TableWrapper>
        </>
        );
    }

    render() {
        const { dataLoaded } = this.state;
        const { authData } = this.props;
        return <AppWrapper 
                subtitle="Setup"
                title="LMS Users"
                onLoad={( !( dataLoaded ) ? true : false )}
                contents={ authData ? this.renderContents() : null } />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        mentorsList: state.mentors && state.mentors.list ? state.mentors.list : null,
        randNum: state.mentors && state.mentors.rand ? state.mentors.rand : null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        schedulesList: state.schedules && state.schedules.schedules_options ? state.schedules.schedules_options : false,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(DatabaseMentorsPage);
/* eslint-disable */
import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import firebase from "firebase/app";
import moment from "moment";
import styled from "styled-components";
import Papa from "papaparse";
import shortid from "shortid";
import blue from "@material-ui/core/colors/blue";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dropzone from "react-dropzone";
import _forEach from "lodash/forEach";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _slice from "lodash/slice";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _find from "lodash/find";
import _toLower from "lodash/toLower";

import ModalView from "../../components/ModalView";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormSelect2 from "../../components/FormSelect2";
import Table from "../../components/Table";
import DotsLoader from "../../components/DotsLoader";

import { InfoButton, GreyButton, ButtonGroup, AInfoLink, InverseButton, ErrorButton, IndigoButton } from "../../styles/button";
import { SuccessTag, GreyTag } from "../../styles/tag";
import { WrapWord } from "../../styles/misc";
import { InfoBox, WarningBox } from "../../styles/message";

import { isAdmin } from "../../helpers/auth";
import { callFunctionsAPI, getAPIErrorMessage } from "../../helpers/action";
import { triggerErrorAlert } from "../../helpers/alert";
import { getMomentTime } from "../../helpers/date";
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, getSelectedValue, trimEmail } from "../../helpers/data";
import { isArrayExists, validateEmail } from "../../helpers/validation";

const IssueNewBadge = ({
    authData,
    dispatch,
    history,
    open = false,
    report = {},
    badges = [],
    certificates = [],
    onBadgePreview = () => {},
    onSubmit = () => {},
    onClose = () => {},
}) => {
    const [loaded, setLoaded] = useState(false);
    const [formData, setFormData] = useState({});
    const [confirmSubmit, setConfirmSubmit] = useState(false);

    useEffect(() => {
        if (open) {
            if (report && report.id && !_isEmpty(report.id)) {
                setLoaded(false);
                firebase
                    .database()
                    .ref("badges_oneoff_reports_list/" + report.id)
                    .once("value")
                    .then((snapshot) => {
                        let list = [];
                        if (snapshot && snapshot.exists() && snapshot.hasChildren()) {
                            snapshot.forEach((childSnapshot) => {
                                let item = childSnapshot.val();
                                item.id = childSnapshot.key;
                                list.push(item);
                            });
                        }
                        let newFormData = {
                            ...report,
                            recipients: _sortBy(list, [(i) => i.name.toLowerCase()]),
                        };
                        if (report.status && report.status === "duplicate") {
                            newFormData.status = "draft"; // change it to draft
                            newFormData.id = ""; // reset id
                        }
                        setFormData(newFormData);
                        setLoaded(true);
                    })
                    .catch((error) => {
                        setLoaded(false);
                        triggerErrorAlert(getAPIErrorMessage(error));
                    });
            } else {
                // reset formData
                setLoaded(true);
                setFormData({
                    name: "",
                    notes: "",
                    badge_id: "",
                    certificate_id: "",
                    recipients: [],
                });
            }
        }
    }, [open, report]);

    const handleSaveAsDraft = () => {
        let submitData = { ...formData, status: "draft" };
        // if doesn't have an ID already, generate one
        if (!(submitData.id && !_isEmpty(submitData.id))) submitData.id = shortid.generate();

        onSubmit(submitData);
    };

    const handleSubmition = () => {
        let submitData = { ...formData, status: "issue" };
        // if doesn't have an ID already, generate one
        if (!(submitData.id && !_isEmpty(submitData.id))) submitData.id = shortid.generate();

        onSubmit(submitData);
        setConfirmSubmit(false);
    };

    const handleAction = () => {
        let error = false;
        // do error check
        if (!(formData && formData.recipients && !_isEmpty(formData.recipients))) error = "Please upload at least one recipient";

        if (!(formData && formData.badge_id && !_isEmpty(formData.badge_id))) error = "Please select a badge";

        if (!(formData && formData.name && !_isEmpty(formData.name))) error = "Please insert a title";

        if (error) {
            triggerErrorAlert(error);
        } else {
            setConfirmSubmit(true);
        }
    };

    const handleFormUpdate = (newValue, name) => {
        switch (name) {
            case "badge_id":
                const selectedBadge = _find(badges, { id: newValue });
                setFormData({ ...formData, [name]: newValue, certificate_id: (selectedBadge && selectedBadge.cert_referer) || "" });
                break;
            default:
                setFormData({ ...formData, [name]: newValue });
                break;
        }
    };

    const handleFileUpload = (files) => {
        if (files && files[0] && !_isEmpty(files[0])) {
            Papa.parse(files[0], {
                header: true,
                complete: (results) => {
                    let list = [];
                    if (results && results.data && isArrayExists(results.data)) {
                        results.data.forEach((item) => {
                            const email = item && item.email && !_isEmpty(item.email) ? trimEmail(item.email) : false;
                            if (email && validateEmail(email) && !_find(list, { email })) {
                                list.push({
                                    id: shortid.generate(),
                                    name: item.name || "",
                                    email,
                                });
                            }
                        });
                    }
                    setFormData({ ...formData, recipients: list });
                },
            });
        } else {
            triggerErrorAlert("Please upload a valid CSV file");
        }
    };

    return (
        <>
            <ModalView
                open={open}
                title={"Issue New Badge"}
                maxWidth="lg"
                actionLabel={"Issue Now"}
                cancelLabel="Close"
                doAction={handleAction}
                customActions={
                    <>
                        <DialogActions>
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    onClose();
                                }}
                                color="default"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleSaveAsDraft();
                                }}
                                color="secondary"
                                variant="contained"
                            >
                                Save as Draft
                            </Button>
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleAction();
                                }}
                                color="primary"
                                variant="contained"
                            >
                                Issue Now
                            </Button>
                        </DialogActions>
                    </>
                }
                disableAutoFocus={true}
                disableEnforceFocus={true}
                disableBackdrop={true}
                onClose={onClose}
                contents={
                    loaded ? (
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <WarningBox style={{ marginBottom: "10px" }}>
                                        <i className="fa fa-exclamation-triangle"></i>WARNING:
                                        <br />
                                        <ol style={{ paddingLeft: "10px", listStyle: "numeric" }}>
                                            <li>An email will be sent to the recipients.</li>
                                            <li>There will be no backpack created for the recipients who are not on our LMS.</li>
                                            <li>
                                                If they already have an existing backpack, the badge will be stored in their backpack, which can be
                                                accessed via LMS.
                                            </li>
                                            <li>
                                                If there are 2 recipients with the same email address, only 1 badge will issued. However if
                                                unavoidable, this can be resolved using{" "}
                                                <a
                                                    href="https://support.google.com/a/users/answer/9282734?hl=en#zippy=%2Clearn-how"
                                                    target={"_blank"}
                                                >
                                                    "+ email"
                                                </a>{" "}
                                                method.
                                            </li>
                                            <li>You cannot add more recipients after this.</li>
                                        </ol>
                                    </WarningBox>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormInput
                                        label={"Title *"}
                                        tooltip={
                                            <>
                                                FOR INTERNAL REFERENCE ONLY
                                                <br />
                                                <br />
                                                This is just a title for future references
                                            </>
                                        }
                                        name="name"
                                        type="text"
                                        value={formData.name || ""}
                                        onChange={handleFormUpdate}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormSelect2
                                        label="Badge *"
                                        value={formData.badge_id || ""}
                                        name="badge_id"
                                        tooltip={
                                            <>
                                                CREATE BADGE FIRST
                                                <br />
                                                <br />
                                                Please{" "}
                                                <a href="https://sms.chumbaka.asia/maintenance/one_off_badges" target="_blank">
                                                    create the one-off badge first
                                                </a>
                                                .
                                            </>
                                        }
                                        placeholder="Select Badge"
                                        isSearchable={true}
                                        options={getSelectOptions({
                                            // list: _sortBy( badges, b => _toLower( b.name ) ),
                                            list: _sortBy(badges, (b) => _toLower(b.name)),
                                            options: [],
                                            keys: { value: "id", label: "name" },
                                        })}
                                        onChange={handleFormUpdate}
                                    />
                                    {formData.badge_id && !_isEmpty(formData.badge_id) && (
                                        <div style={{ paddingTop: "5px" }}>
                                            <IndigoButton
                                                size="small"
                                                style={{ padding: "5px 10px" }}
                                                onClick={() => onBadgePreview(formData.badge_id)}
                                            >
                                                Preview Badge
                                            </IndigoButton>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <FormInput
                                        label={"Notes"}
                                        // tooltip={(
                                        // <>
                                        //     FOR INTERNAL REFERENCE ONLY<br /><br />
                                        //     This is just a title for future references
                                        // </>
                                        // )}
                                        name="notes"
                                        multiline={true}
                                        rows={3}
                                        value={formData.notes || ""}
                                        onChange={handleFormUpdate}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                    {formData.recipients && isArrayExists(formData.recipients) ? (
                                        <div style={{ padding: "10px 5px" }}>
                                            <Typography variant="h5">Recipient(s):</Typography>
                                            <div style={{ padding: "10px 0" }}>
                                                <InverseButton
                                                    size="small"
                                                    style={{ padding: "5px 15px" }}
                                                    onClick={() => setFormData({ ...formData, recipients: [] })}
                                                >
                                                    <i className="fa fa-refresh"></i>Reset
                                                </InverseButton>
                                            </div>
                                            <Table
                                                items={formData.recipients}
                                                emptyCell="No recipient found"
                                                cells={[
                                                    { id: "name", label: "* Name", render: (item) => <WrapWord>{item.name || ""}</WrapWord> },
                                                    { id: "email", label: "* Email", render: (item) => <WrapWord>{item.email || ""}</WrapWord> },
                                                ]}
                                                noAction={true}
                                                elevation={0}
                                            />
                                        </div>
                                    ) : (
                                        <div style={{ padding: "20px 5px" }}>
                                            <Typography variant="h5">Upload Recipient(s)</Typography>
                                            <div style={{ padding: "10px 0" }}>
                                                <AInfoLink
                                                    size="small"
                                                    href="https://firebasestorage.googleapis.com/v0/b/chumbaka-dev-tms.appspot.com/o/file_storage%2Fissue_one_off_badge_sample.csv?alt=media&token=eaba5b42-c162-4865-8886-704d2a30ba9a"
                                                    style={{ padding: "5px 15px" }}
                                                >
                                                    Download Sample CSV File
                                                </AInfoLink>
                                            </div>
                                            <Dropzone onDrop={handleFileUpload} accept={".csv"}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div
                                                        {...getRootProps({
                                                            style: {
                                                                display: "flex",
                                                                height: "200px",
                                                                width: "100%",
                                                                border: "2px dashed " + blue["700"],
                                                                color: blue["700"],
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                cursor: "pointer",
                                                            },
                                                        })}
                                                    >
                                                        <input {...getInputProps()} />
                                                        <div>Drop a CSV file here, or click to select a CSV file to upload</div>
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <DotsLoader />
                    )
                }
            />

            <ModalView
                open={confirmSubmit}
                title={"Please Confirm Your Action"}
                maxWidth="sm"
                actionLabel={"Confirm"}
                cancelLabel="Close"
                doAction={handleSubmition}
                onClose={() => setConfirmSubmit(false)}
                contents={
                    <>
                        <Typography variant="h5" style={{ marginBottom: "15px" }}>{`YOU ARE ABOUT TO ISSUE ONE-OFF BADGES TO ${_size(
                            formData.recipients
                        )} RECIPIENTS.`}</Typography>
                        <WarningBox style={{ marginBottom: "10px" }}>
                            <i className="fa fa-exclamation-triangle"></i>WARNING:
                            <br />
                            <ol style={{ paddingLeft: "10px", listStyle: "numeric" }}>
                                <li>An email will be sent to the recipients.</li>
                                <li>There will be no backpack created for the recipients who are not on our LMS.</li>
                                <li>
                                    If they already have an existing backpack, the badge will be stored in their backpack, which can be accessed via
                                    LMS.
                                </li>
                                <li>
                                    If there are 2 recipients with the same email address, only 1 badge will issued. However if unavoidable, this can
                                    be resolved using{" "}
                                    <a href="https://support.google.com/a/users/answer/9282734?hl=en#zippy=%2Clearn-how" target={"_blank"}>
                                        "+ email"
                                    </a>{" "}
                                    method.
                                </li>
                                <li>You cannot add more recipients after this.</li>
                            </ol>
                        </WarningBox>
                    </>
                }
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(IssueNewBadge);

/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import { CopyToClipboard } from "react-copy-to-clipboard";
import _forEach from "lodash/forEach";
import _size from "lodash/size";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _slice from "lodash/slice";
import _sortBy from "lodash/sortBy";
import _reverse from "lodash/reverse";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _toLower from "lodash/toLower";
import _split from "lodash/split";
import _toString from "lodash/toString";

import { Projects } from "../../modules/TeamShowcaseForm";
import FeedbackFormAnswers from "./feedback_form_answers";

import ModelDelete from "../../components/ModalDelete";
import ModalView from "../../components/ModalView";
import FormInput from "../../components/FormInput";
import FormSelect from "../../components/FormSelect";
import FormSelect2 from "../../components/FormSelect2";
import FormRadio from "../../components/FormRadio";
import FormCheckbox from "../../components/FormCheckbox";
import ButtonActions from "../../components/ButtonActions";
import StudentEnrollmentStatus from "../../components/StudentEnrollmentStatus";
import ToolTipInfo from "../../components/ToolTipInfo";

import {
    InfoButton,
    GreyButton,
    ButtonGroup,
    AInfoLink,
    InverseButton,
    ErrorButton,
    WarningButton,
    SuccessButton,
    WhiteButton,
    AWhiteLink,
    AIndigoLink,
    YellowButton,
} from "../../styles/button";
import { SuccessTag, GreyTag, InfoTag, AmberTag, ErrorTag } from "../../styles/tag";
import { ErrorBox, WarningBox } from "../../styles/message";
import { WrapWord } from "../../styles/misc";

import { isArrayExists, isObjectExists } from "../../helpers/validation";
import { triggerErrorAlert, triggerSuccessAlert } from "../../helpers/alert";
import {
    cloneCollections,
    doPagination,
    doArraySearch,
    getSelectOptions,
    getSelectValues,
    getSelectedValue,
    compareString,
} from "../../helpers/data";
import { getMomentTime, getMomentTimestamp, getAge } from "../../helpers/date";
import { isAdmin, hasAccessRights, getUserRegionFilter, isAdminType } from "../../helpers/auth";
import {
    getCourseBadges,
    getSelectedTraineeData,
    getTraineeCourseFromShowcase,
    getFeedbackformFromTrainee,
    getShowcaseStatus,
    getBadgesOptions,
    isCompetitionModule,
    getTeamProjectSubmission,
    getTraineeTeamData,
    isBadgeIssuedFromTransferedClass,
    getTransferTraineeShowcaseData,
    convertTranferedTraineeShowcaseData,
} from "../../helpers/showcase";
// import { getStudentEnrollmentStatus } from '../../helpers/trainees';
// import { doSchemaErrorCheck } from '../../helpers/schemas';

import { appChangesMade } from "../../actions/misc";

import { traineeSchema } from "../../schemas/trainee";

import { PLATFORM_COURSE_REGISTRATION_LINK, GENDER_OPTIONS, ENROLLMENT_STATUS_OPTIONS, YES_NO_OPTIONS, PLATFORM_BADGE_URL } from "../../constants";

const SuccessIcon = styled.i`
    color: #64dd17;
`;

const GreyIcon = styled.i`
    color: #64dd17;
`;

const WarningIcon = styled.i`
    color: #ff6f00;
`;

const CourseColumn = styled.div`
    padding: 16px;
    font-size: 11px;
    height: 100%;
    width: 100%;
    max-width: 200px;
    ${(props) => {
        if (props.overdue && props.overdue === "yes") {
            return `
            background: #ffebee;
            `;
        }
        if (props.disabled && props.disabled === "yes") {
            return `
            .disabled-wrapper {
                opacity: 0.4;
            }
            `;
        }
    }};
`;

const RadioWrapper = styled.div`
    cursor: default;
    pointer-events: none;
    .MuiFormControl-fullWidth {
        background: none;
    }
    .MuiFormLabel-root,
    .MuiTypography-body1 {
        font-size: 11px;
    }
`;

const TextareaWrapper = styled.div`
    textarea {
        width: 100%;
        max-width: 150px;
        font-size: 11px;
        padding: 2px;
        overflow: scroll-y;
    }
`;

class ReviewShowcase extends React.Component {
    state = {
        openFeedbackModal: false,
        openShowcaseModal: false,
        modalData: false,
        traineeData: false,
    };

    handleShowcaseUpdate = () => {
        const { authData, onTraineeUpdate, badges, schedule } = this.props;
        const { modalData, modalType, traineeData } = this.state;

        let error = false,
            newTraineeData = traineeData && isObjectExists(traineeData) ? cloneCollections(traineeData) : false;

        // make sure comments doesn't exceed 1000 chars
        if (modalData && modalData.project_feedback && !_isEmpty(modalData.project_feedback) && _size(modalData.project_feedback) > 1000)
            error = "Project's feedback cannot be exceed more than 1000 characters";

        if (!isCompetitionModule(schedule) && !(modalData.project_feedback && !_isEmpty(modalData.project_feedback)))
            error = "Please fill out the project's feedback";

        if (!newTraineeData) error = "Missing student data - please try again.";

        if (newTraineeData) {
            let showcaseIndex =
                    traineeData && traineeData.showcase && isArrayExists(traineeData.showcase)
                        ? _findIndex(traineeData.showcase, (s) => _toString(s.id) === _toString(modalData.course_id))
                        : -1,
                showcaseData =
                    showcaseIndex >= 0
                        ? cloneCollections(traineeData.showcase[showcaseIndex])
                        : {
                              id: _toString(modalData.course_id),
                              badge_id: "", // badge id
                              badge_type: "", // badge type ID
                              score: "", // showcase score
                          };

            // update project title & url
            if (modalData.project_title_1 && !_isEmpty(modalData.project_title_1)) {
                showcaseData.project_title_1 = modalData.project_title_1;
            }

            if (modalData.project_url_1 && !_isEmpty(modalData.project_url_1)) {
                showcaseData.project_url_1 = modalData.project_url_1;
            }

            // update score
            if (modalData.score && !_isEmpty(modalData.score) && modalData.course_id && !_isEmpty(modalData.course_id)) {
                showcaseData.score = modalData.score === "pass" ? "pass" : "fail";
            } // end - modalData.score

            // update badge
            if (
                modalData.score &&
                modalData.score === "pass" &&
                modalData.badge_type &&
                !_isEmpty(modalData.badge_type) &&
                modalData.course_id &&
                !_isEmpty(modalData.course_id)
            ) {
                const coursebadges = getCourseBadges({ lms_course_id: (modalData && modalData.course_id) || "", badges, schedule });
                let selected_badge = coursebadges && isArrayExists(coursebadges) ? _find(coursebadges, { type: modalData.badge_type }) : false;
                if (selected_badge && selected_badge.id && !_isEmpty(selected_badge.id)) {
                    // only for admin & superadmin
                    if (authData && isAdminType(authData)) {
                        if (modalData.badge_id && !_isEmpty(modalData.badge_id) && selected_badge.id === modalData.badge_id) {
                            showcaseData.badge_id = selected_badge.id;
                        } else {
                            showcaseData.badge_id = "";
                        }
                    } // end - authData
                    showcaseData.badge_type = selected_badge.type || "";
                } // end - selected_badge
            } else {
                // reset badge
                showcaseData.badge_id = "";
                showcaseData.badge_type = "";
            } // end - modalData.score

            // add project feedback
            showcaseData.project_feedback = modalData.project_feedback || "";
            // showcaseData.project_reenabled = ( modalData.project_reenabled && ( modalData.project_reenabled === 'yes' || modalData.project_reenabled === 'no' ) ? modalData.project_reenabled : 'no' );

            // update showcase
            if (showcaseIndex >= 0) {
                newTraineeData.showcase[showcaseIndex] = showcaseData;
            } else {
                if (!(newTraineeData.showcase && isArrayExists(newTraineeData.showcase))) newTraineeData.showcase = [];

                newTraineeData.showcase.push(showcaseData);
            } // end - showcaseIndex

            // do error check
            if (showcaseData.score && showcaseData.score === "pass" && !(showcaseData.badge_type && !_isEmpty(showcaseData.badge_type)))
                error = "Please select a badge type";

            if (!(showcaseData.score && !_isEmpty(showcaseData.score))) error = "Please select a score";
        } // end - newTraineeData

        if (error) {
            triggerErrorAlert(error);
        } else {
            // do update
            onTraineeUpdate(newTraineeData, traineeData.id);
            this.setState({ openShowcaseModal: false, modalData: false, traineeData: false, modalType: false });
        } // end - error
    };

    handleFormUpdate = (newValue, key, subkey, subVal) => {
        const { badges, schedule } = this.props;
        const { modalData } = this.state;
        let newData = modalData ? cloneCollections(modalData) : {};
        switch (key) {
            case "score":
                newData[key] = newValue;
                if (newValue && newValue === "fail") {
                    newData.badge_id = "";
                    newData.badge_type = "";
                }
                break;
            case "badge_id":
                if (subkey && subkey === "checked") {
                    const coursebadges = getCourseBadges({ lms_course_id: (modalData && modalData.course_id) || "", badges, schedule });
                    newData[key] = getSelectedValue(coursebadges, modalData.badge_type, "type", "id", "");
                } else {
                    newData[key] = "";
                }
                break;
            default:
                if (subkey && (subkey === "checked" || subkey === "unchecked") && subVal && !_isEmpty(subVal)) {
                    // use subVal as the new value
                    newData[key] = subVal;
                } else {
                    // otherwise - just update normally
                    newData[key] = newValue;
                }
                break;
        } // end - key
        this.setState({ modalData: newData });
    };

    // getCourseBadges = (lms_course_id) => {
    //     const { badges, schedule } = this.props;
    //     let course = ( schedule && schedule.course_access && isArrayExists( schedule.course_access ) && lms_course_id ? _find( schedule.course_access, (c) => compareString( c.lms_course_id, lms_course_id ) ) : false );
    //     return ( course && course.id && badges && isArrayExists( badges ) ? _filter( badges, b => b.references && compareString( b.references.course_id, course.id ) ? true : false ) : [] );
    // }

    // getCurrentTrainee = (trainee) => {
    //     const { currentTrainees } = this.props;
    //     return ( currentTrainees && isArrayExists( currentTrainees ) && trainee && trainee.id ? _find( currentTrainees, { id: trainee.id } ) : false );
    // }

    // getSelectedCourseAccess= (trainee,course_id) => {
    //     return ( trainee && trainee.course_access && isArrayExists( trainee.course_access ) ? _find( trainee.course_access, b => _toString( b.id ) === _toString( course_id ) ) : false );
    // }

    // getSelectedShowcase = (trainee,course_id) => {
    //     return ( trainee && trainee.showcase && isArrayExists( trainee.showcase ) ? _find( trainee.showcase, b => _toString( b.id ) === _toString( course_id ) ) : false );
    // }

    // getSelectedTraineeFromShowcase = (trainee) => {
    //     const { showcase } = this.props;
    //     return ( trainee && trainee.id && !_isEmpty(trainee.id) && showcase && showcase.trainees && isArrayExists( showcase.trainees ) ? _find( showcase.trainees, { id: trainee.id }) : false );
    // }

    // getSelectedTraineeCourseFromShowcase = (trainee,course_id) => {
    //     const { showcase } = this.props;
    //     const traineeData = getTraineeFromShowcase({ trainee, showcase });
    //     return ( traineeData && traineeData.courses && isArrayExists( traineeData.courses ) ? _find( traineeData.courses, c => _toString( c.id ) === _toString( course_id ) ) : false );
    // }

    // getFeedbackformFromTrainee = (trainee,course_id) => {
    //     const { showcase } = this.props;
    //     const course = getTraineeCourseFromShowcase({ trainee, lms_course_id: course_id, showcase });
    //     return ( course && course.feedback_form_answers && isArrayExists( course.feedback_form_answers ) ? course.feedback_form_answers : false );
    // }

    // getShowcaseStatus = (trainee,course_id) => {
    //     const { currentTrainees, showcase } = this.props;
    //     const showcaseData = getSelectedTraineeData({ key: 'showcase', trainee: getCurrentTrainee({ currentTrainees, trainee }), lms_course_id: course_id });
    //     const feedbackForm = getFeedbackformFromTrainee({ trainee, lms_course_id: course_id, showcase });
    //     let status = 'action_needed'; // ignore feedbackForm
    //     if ( showcaseData && showcaseData.badge_id && !_isEmpty( showcaseData.badge_id ) ) {
    //         status = 'issued';
    //     } else if ( feedbackForm ) {
    //         status = 'action_needed';
    //     }
    //     return status;
    // }

    // getShowcaseDateOptions = () => {
    //     const { schedule } = this.props;
    //     let options = [{ value: 'all', label: 'All Open Days Dates' }];
    //     if ( schedule && schedule.class_calendar && isArrayExists( schedule.class_calendar ) ) {
    //         _forEach( _sortBy( schedule.class_calendar, ['start_date'] ), event => {
    //             if ( event.showcase && event.showcase === 'yes' )
    //                 options.push({ value: event.id, label: ( event.start_date ? getMomentTime( event.start_date, 'DD MMM YYYY' ) : '' ) })
    //         });
    //     } // end - schedule.class_calendar
    //     return options;
    // }

    // getBadgesOptions = () => {
    //     const { badges, trainee } = this.props;
    //     const { modalData } = this.state;
    //     let options = [{ value: '', label: 'Select an Option' }],
    //         courseAccess = getSelectedTraineeData({ key: 'course_access', trainee, lms_course_id: (  modalData && modalData.course_id ? modalData.course_id : false  ) }),
    //         // courseProfile = ( modalData && modalData.course_id ? _find( courses, c => _toString( c.lms_course_id ) === _toString( modalData.course_id ) ) : false ),
    //         courseBadges = ( courseAccess && courseAccess.course_id ? _filter( badges, b => b.references && b.references.course_id && b.references.course_id === courseAccess.course_id ) : false );
    //     if ( courseBadges && isArrayExists( courseBadges ) ) {
    //         _forEach( _sortBy( courseBadges, ['type_label'] ), badge => {
    //             options.push({
    //                 value: badge.type,
    //                 label: ( badge.type_label || '' )
    //             });
    //         });
    //     } // end - courseBadges
    //     return options
    // }

    // checkTraineeShowcaseStatus = (trainee,status) => {
    //     const { currentTrainees, showcase } = this.props;
    //     let valid = false;
    //     if ( trainee && trainee.course_access && isArrayExists( trainee.course_access ) ) {
    //         _forEach( trainee.course_access, course => {
    //             if ( course && course.id ) {
    //                 const showcaseStatus = getShowcaseStatus({ trainee, lms_course_id: course.id, currentTrainees, showcase });
    //                 if ( status === 'ineligible' ) {
    //                     if ( !showcaseStatus )
    //                         valid = true;
    //                 } else {
    //                     if ( showcaseStatus === status )
    //                         valid = true;
    //                 } // end - status
    //             } // end - course
    //         });
    //     } // end - trainee.course_access
    //     return valid;
    // }

    getCourseShowcaseDataFromTrainee = (trainee, course_id, key, defaulVal = "") => {
        const { showcase } = this.props;
        const course = getTraineeCourseFromShowcase({ trainee, lms_course_id: course_id, showcase });
        let showcaseData =
            trainee.showcase && isArrayExists(trainee.showcase) ? _find(trainee.showcase, (b) => compareString(b.id, course_id)) : false;
        const isTransferredBadge = isBadgeIssuedFromTransferedClass({ trainee, lms_course_id: course_id });
        if (isTransferredBadge) {
            const transferShowcaseData = getTransferTraineeShowcaseData({ trainee, lms_course_id: course_id });
            if (transferShowcaseData) {
                showcaseData = convertTranferedTraineeShowcaseData(transferShowcaseData);
            }
        }
        switch (key) {
            case "booking_date":
            case "showcase_date":
            case "nominated_on":
            case "approved_on":
                return course && course[key] ? getMomentTime(course[key], "DD MMM YYYY") : "---";
            case "project_title_1":
            case "project_url_1":
            case "project_feedback":
                return showcaseData && showcaseData[key] ? showcaseData[key] : defaulVal;
            case "project_reenabled":
            default:
                return course && course[key] && !_isEmpty(course[key]) ? course[key] : defaulVal || "";
        }
    };

    renderShowcaseForm = () => {
        const { authData, rubrics, courses, schedule, badges, showcase, currentTrainees, trainee, onTraineeUpdate } = this.props;
        const { modalData, modalType, traineeData } = this.state;
        const coursebadges = getCourseBadges({ lms_course_id: (modalData && modalData.course_id) || "", badges, schedule });
        const status = getShowcaseStatus({ trainee: traineeData, lms_course_id: modalData.course_id, currentTrainees, showcase }),
            feedbackForm = getFeedbackformFromTrainee({ trainee: traineeData, lms_course_id: modalData.course_id, showcase }),
            course = modalData && modalData.course_id ? _find(courses, (c) => compareString(c.lms_course_id, modalData.course_id)) : false,
            rubric = course && course.course_rubric && !_isEmpty(course.course_rubric) ? _find(rubrics, { id: course.course_rubric }) : false;
        const team = isCompetitionModule(schedule) ? getTraineeTeamData({ teams: showcase.teams || [], trainee_id: traineeData.id }) : false;
        const courseShowcase = getTraineeCourseFromShowcase({ trainee: traineeData, lms_course_id: modalData.course_id, showcase });
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <FormInput
                            label="Course Enabled"
                            name="course_enabled"
                            value={course && course.course_code ? course.course_code : ""}
                            onChange={() => {
                                /* do nothing */
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <ButtonGroup style={{ margin: "20px 0 0 5px" }}>
                            <AWhiteLink
                                href={rubric && rubric.value && !_isEmpty(rubric.value) ? rubric.value : ""}
                                target="_blank"
                                style={{ padding: "5px 15px", border: "2px solid #212121" }}
                            >
                                <i className="fa fa-globe"></i>View Rubric
                            </AWhiteLink>
                            <WhiteButton
                                onClick={() => this.setState({ openFeedbackModal: true })}
                                style={{ padding: "5px 15px", border: "2px solid #212121" }}
                            >
                                <i className="fa fa-edit"></i>View Feedback
                            </WhiteButton>
                        </ButtonGroup>
                    </Grid>
                    {isCompetitionModule(schedule) ? (
                        <>
                            <Projects
                                projects={getTeamProjectSubmission({ teams: showcase.teams || [], id: (team && team.id) || false, schedule })}
                            />
                        </>
                    ) : (
                        <>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Open Day Date Booked"
                                    name="showcase_date"
                                    value={this.getCourseShowcaseDataFromTrainee(traineeData, modalData.course_id, "showcase_date")}
                                    onChange={() => {
                                        /* do nothing */
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Booking Date"
                                    name="booking_date"
                                    value={this.getCourseShowcaseDataFromTrainee(traineeData, modalData.course_id, "booking_date")}
                                    onChange={() => {
                                        /* do nothing */
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    label="Showcase Project Title"
                                    name="showcase_project_title"
                                    value={
                                        modalData.project_title_1 && !_isEmpty(modalData.project_title_1)
                                            ? modalData.project_title_1
                                            : this.getCourseShowcaseDataFromTrainee(traineeData, modalData.course_id, "project_title")
                                    }
                                    onChange={(newValue) => {
                                        this.handleFormUpdate(newValue, "project_title_1");
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={11}>
                                        <FormInput
                                            label="Showcase Project Link"
                                            name="showcase_project_desc"
                                            value={
                                                modalData.project_url_1 && !_isEmpty(modalData.project_url_1)
                                                    ? modalData.project_url_1
                                                    : this.getCourseShowcaseDataFromTrainee(traineeData, modalData.course_id, "project_desc")
                                            }
                                            onChange={(newValue) => {
                                                this.handleFormUpdate(newValue, "project_url_1");
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={1} align="center">
                                        <AInfoLink
                                            size="small"
                                            minWidth="0px"
                                            href={
                                                modalData.project_url_1 && !_isEmpty(modalData.project_url_1)
                                                    ? modalData.project_url_1
                                                    : this.getCourseShowcaseDataFromTrainee(traineeData, modalData.course_id, "project_desc")
                                            }
                                            target="_blank"
                                            rel="nofollow"
                                            noIconMargin="yes"
                                        >
                                            <i className="fa fa-globe"></i>
                                        </AInfoLink>
                                    </Grid>
                                </Grid>
                                {status && status === "issued" && (
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={modalData.project_reenabled && modalData.project_reenabled === "yes" ? true : false}
                                                    onChange={(e) => {
                                                        const project_reenabled = e.target.checked ? "yes" : "no";
                                                        this.handleFormUpdate(project_reenabled, "project_reenabled");
                                                        const newTraineeData = { ...traineeData };
                                                        if (newTraineeData) {
                                                            let showcaseIndex =
                                                                    traineeData && traineeData.showcase && isArrayExists(traineeData.showcase)
                                                                        ? _findIndex(
                                                                              traineeData.showcase,
                                                                              (s) => _toString(s.id) === _toString(modalData.course_id)
                                                                          )
                                                                        : -1,
                                                                showcaseData =
                                                                    showcaseIndex >= 0
                                                                        ? cloneCollections(traineeData.showcase[showcaseIndex])
                                                                        : {
                                                                              id: _toString(modalData.course_id),
                                                                              badge_id: "", // badge id
                                                                              badge_type: "", // badge type ID
                                                                              score: "", // showcase score
                                                                          };

                                                            // update project_reenabled
                                                            showcaseData.project_reenabled = project_reenabled;

                                                            // update showcase
                                                            if (showcaseIndex >= 0) {
                                                                newTraineeData.showcase[showcaseIndex] = showcaseData;
                                                            }
                                                            onTraineeUpdate(newTraineeData, traineeData.id);
                                                        }
                                                    }}
                                                />
                                            }
                                            label="Re-enable Project Submission"
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    label={"Showcase Project Feedback *"}
                                    tooltip={
                                        <>
                                            FEEDBACK ON SHOWCASE PROJECT
                                            <br />
                                            <br />
                                            This will be emailed to students & parents.
                                            <br />
                                            Provide feedback to student on his/her showcase project. Use rubric to help you.
                                        </>
                                    }
                                    required={true}
                                    disabled={modalType && modalType === "view_only" ? true : false}
                                    rows={4}
                                    multiline={true}
                                    name="project_feedback"
                                    value={(modalData && modalData.project_feedback) || ""}
                                    onChange={this.handleFormUpdate}
                                />
                                {modalData &&
                                modalData.project_feedback &&
                                !_isEmpty(modalData.project_feedback) &&
                                _size(modalData.project_feedback) > 1000 ? (
                                    <ErrorBox>
                                        Please reduce your notes to less than 1000 characters. Current size: {_size(modalData.project_feedback)}{" "}
                                        characters.
                                    </ErrorBox>
                                ) : null}
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} sm={12}>
                        <div style={{ padding: "0px 10px 10px 10px" }}>
                            <FormRadio
                                inline={true}
                                label="Update Scoring"
                                name="score"
                                value={
                                    modalData && modalData.score && (modalData.score === "pass" || modalData.score === "fail") ? modalData.score : ""
                                }
                                options={[
                                    // { value: '', label: 'Select an Option' },
                                    { value: "pass", label: "PASS" },
                                    { value: "fail", label: "FAIL" },
                                ]}
                                disabled={(modalType && modalType === "view_only") || isCompetitionModule(schedule) ? true : false}
                                radioIcon={<CheckBoxOutlineBlankIcon color="disabled" />}
                                radioCheckedIcon={
                                    modalData && modalData.score && modalData.score === "pass" ? (
                                        <ThumbUpIcon style={{ color: green[700] }} />
                                    ) : modalData && modalData.score && modalData.score === "fail" ? (
                                        <ThumbDownIcon color="error" />
                                    ) : (
                                        <CheckBoxOutlineBlankIcon color="disabled" />
                                    )
                                }
                                onChange={this.handleFormUpdate}
                            />
                        </div>
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                    <span style={{ marginRight: '8px', fontSize: '18px', fontWeight: '700' }}>Feedback Form:</span>{ feedbackForm ? <SuccessTag><i className="fa fa-check"></i></SuccessTag> : <GreyTag><i className="fa fa-times"></i></GreyTag> }
                </Grid> */}
                    <Grid item xs={12} sm={6}>
                        <FormSelect
                            disabled={
                                modalData.score &&
                                modalData.score === "pass" &&
                                !(modalType && modalType === "view_only") &&
                                !isCompetitionModule(schedule)
                                    ? false
                                    : true
                            }
                            label={
                                authData && isAdminType(authData) && modalData && modalData.badge_type && !_isEmpty(modalData.badge_type)
                                    ? "Nominated Badge"
                                    : "Nominate Badge"
                            }
                            name="badge_type"
                            value={modalData && modalData.badge_type ? modalData.badge_type : ""}
                            options={getBadgesOptions({
                                badges,
                                trainee,
                                lms_course_id: modalData && modalData.course_id ? modalData.course_id : false,
                            })}
                            onChange={this.handleFormUpdate}
                        />
                        {this.getCourseShowcaseDataFromTrainee(traineeData, modalData.course_id, "nominated_by") &&
                        "" !== this.getCourseShowcaseDataFromTrainee(traineeData, modalData.course_id, "nominated_by") ? (
                            <div style={{ paddingTop: "5px" }}>
                                <Typography variant="body2">
                                    By: {this.getCourseShowcaseDataFromTrainee(traineeData, modalData.course_id, "nominated_by")}
                                </Typography>
                                <Typography variant="body2">
                                    On: {this.getCourseShowcaseDataFromTrainee(traineeData, modalData.course_id, "nominated_on")}
                                </Typography>
                            </div>
                        ) : this.getCourseShowcaseDataFromTrainee(traineeData, modalData.course_id, "approved_by") &&
                          "" !== this.getCourseShowcaseDataFromTrainee(traineeData, modalData.course_id, "approved_by") ? (
                            <div style={{ paddingTop: "5px" }}>
                                <Typography variant="body2">
                                    By: {this.getCourseShowcaseDataFromTrainee(traineeData, modalData.course_id, "approved_by")}
                                </Typography>
                                <Typography variant="body2">
                                    On: {this.getCourseShowcaseDataFromTrainee(traineeData, modalData.course_id, "approved_on")}
                                </Typography>
                            </div>
                        ) : null}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {authData && isAdminType(authData) && modalData && modalData.badge_type && !_isEmpty(modalData.badge_type) ? (
                            <>
                                <FormCheckbox
                                    label="Approve Nominated Badge"
                                    value={modalData && modalData.badge_id && !_isEmpty(modalData.badge_id) ? modalData.badge_id : "no"}
                                    name="badge_id"
                                    checked={getSelectedValue(coursebadges, modalData.badge_type, "type", "id", "yes")}
                                    unchecked="no"
                                    disabled={modalType && modalType === "view_only" ? true : false}
                                    onChange={this.handleFormUpdate}
                                />
                                {this.getCourseShowcaseDataFromTrainee(traineeData, modalData.course_id, "approved_by") &&
                                "" !== this.getCourseShowcaseDataFromTrainee(traineeData, modalData.course_id, "approved_by") ? (
                                    <div style={{ paddingTop: "5px" }}>
                                        <Typography variant="body2">
                                            By: {this.getCourseShowcaseDataFromTrainee(traineeData, modalData.course_id, "approved_by")}
                                        </Typography>
                                        <Typography variant="body2">
                                            On: {this.getCourseShowcaseDataFromTrainee(traineeData, modalData.course_id, "approved_on")}
                                        </Typography>
                                    </div>
                                ) : null}
                            </>
                        ) : null}
                    </Grid>
                    {modalData && modalData.badge_id && !_isEmpty(modalData.badge_id) && (
                        <Grid item xs={12}>
                            <WarningBox>
                                <i className="fa fa-lightbulb-o"></i>
                                <strong style={{ fontWeight: "700" }}>Thank you for your evaluation!</strong>
                                <br />
                                <br />
                                The student’s course will be concluded after the badge is issued.
                                <br />
                                <br />
                                Concluded enrolments are students who can only view the course in a read-only format. This is the same status that is
                                automatically given to a student when a course is completed by him/her. Read more{" "}
                                <a href="https://s3.amazonaws.com/tr-learncanvas/docs/CanvasEnrollmentStatusComparison.pdf" target="_blank">
                                    here
                                </a>
                                .
                            </WarningBox>
                        </Grid>
                    )}
                    {courseShowcase && courseShowcase.issued_badge_id && !_isEmpty(courseShowcase.issued_badge_id) && (
                        <div style={{ padding: "10px" }}>
                            <AInfoLink
                                href={PLATFORM_BADGE_URL + courseShowcase.issued_badge_id}
                                target="_blank"
                                style={{
                                    padding: "8px 25px",
                                }}
                            >
                                <i className="fa fa-shield"></i>View Badge
                            </AInfoLink>
                        </div>
                    )}
                </Grid>
            </div>
        );
    };

    renderShowcaseFormTitle = () => {
        const { authData } = this.props;
        const { modalData, traineeData, modalType } = this.state;
        return (
            (authData && isAdminType(authData) ? "Issue Badge" : "Nominate Badge") +
            (traineeData && traineeData.name && !_isEmpty(traineeData.name) ? " for " + traineeData.name : "")
        );
    };

    renderScoringRadio = (trainee, course, disabled) => {
        // const course_access = getSelectedTraineeData({ key: 'course_access', trainee, lms_course_id: course.id });
        const showcase = getSelectedTraineeData({ key: "showcase", trainee, lms_course_id: course.id });
        return (
            <RadioWrapper>
                <FormRadio
                    inline={true}
                    label=""
                    name="score"
                    disabled={disabled}
                    radioIcon={<CheckBoxOutlineBlankIcon color="disabled" />}
                    radioCheckedIcon={
                        showcase && showcase.score && showcase.score === "pass" ? (
                            <ThumbUpIcon style={{ color: green[700] }} />
                        ) : showcase && showcase.score && showcase.score === "fail" ? (
                            <ThumbDownIcon color="error" />
                        ) : (
                            <CheckBoxOutlineBlankIcon color="disabled" />
                        )
                    }
                    value={showcase && showcase.score && (showcase.score === "pass" || showcase.score === "fail") ? showcase.score : ""}
                    options={[
                        { value: "pass", label: "PASS" },
                        { value: "fail", label: "FAIL" },
                    ]}
                    onChange={(newValue) => {
                        // disable this to not being able to change it here - also already outdated
                        // let index = ( course_access && course_access.id && trainee && trainee.course_access && isArrayExists( trainee.course_access ) ? _findIndex( trainee.course_access, c => _toString(c.id) === _toString(course_access.id) ) : -1 ),
                        //     newData = ( trainee && !_isEmpty(trainee) ? cloneCollections( trainee ) : {} );
                        // if ( index >= 0 ) {
                        //     newData.course_access[index].score = newValue;
                        // }
                        // if ( trainee && trainee.id )
                        //     this.handleTraineeUpdate(newData,trainee.id);
                    }}
                />
            </RadioWrapper>
        );
    };

    renderShowcaseAction = (trainee, course_id) => {
        const { authData, currentTrainees, showcase } = this.props;
        // const currentTrainee = getCurrentTrainee({ currentTrainees, trainee });
        const showcaseData = getSelectedTraineeData({ key: "showcase", trainee, lms_course_id: course_id });
        const btnProps = {
            size: "small",
            minWidth: "0px",
            style: { padding: "4px 8px", marginTop: "4px" },
        };
        let status = getShowcaseStatus({ trainee, lms_course_id: course_id, currentTrainees, showcase });
        // change to edit if just edited but not save yet
        if (
            status &&
            status === "action_needed" &&
            showcaseData &&
            showcaseData.id &&
            !_isEmpty(showcaseData.id) &&
            (!isAdminType(authData) ||
                (showcaseData.badge_id && !_isEmpty(showcaseData.badge_id)) ||
                (!(showcaseData.badge_id && !_isEmpty(showcaseData.badge_id)) && showcaseData.badge_type && !_isEmpty(showcaseData.badge_type)) ||
                (!(showcaseData.badge_type && !_isEmpty(showcaseData.badge_type)) && showcaseData.score && showcaseData.score === "fail"))
        )
            status = "edit";

        switch (status) {
            case "issued":
                return (
                    <>
                        <SuccessTag style={{ border: "2px solid " + green[700], marginBottom: "5px" }}>Badge Claimed</SuccessTag>
                        <InfoButton
                            {...btnProps}
                            style={{ padding: "4px 8px", marginLeft: "5px" }}
                            noIconMargin="yes"
                            onClick={() =>
                                this.setState({
                                    openShowcaseModal: true,
                                    modalType: "view_only",
                                    traineeData: trainee || false,
                                    modalData: {
                                        trainee_id: trainee.id,
                                        course_id,
                                        badge_id:
                                            showcaseData && showcaseData.badge_id && !_isEmpty(showcaseData.badge_id) ? showcaseData.badge_id : "",
                                        badge_type:
                                            showcaseData && showcaseData.badge_type && !_isEmpty(showcaseData.badge_type)
                                                ? showcaseData.badge_type
                                                : "",
                                        score:
                                            showcaseData && showcaseData.score && (showcaseData.score === "pass" || showcaseData.score === "fail")
                                                ? showcaseData.score
                                                : "",
                                        project_feedback: this.getCourseShowcaseDataFromTrainee(trainee, course_id, "project_feedback"),
                                        project_title_1: this.getCourseShowcaseDataFromTrainee(trainee, course_id, "project_title_1"),
                                        project_url_1: this.getCourseShowcaseDataFromTrainee(trainee, course_id, "project_url_1"),
                                        project_reenabled: this.getCourseShowcaseDataFromTrainee(trainee, course_id, "project_reenabled"),
                                    },
                                })
                            }
                        >
                            <i className="fa fa-search"></i>
                        </InfoButton>
                    </>
                );
            case "action_needed":
                return (
                    <WarningButton
                        {...btnProps}
                        onClick={() =>
                            this.setState({
                                openShowcaseModal: true,
                                modalType: "showcase_form",
                                traineeData: trainee || false,
                                modalData: {
                                    trainee_id: trainee.id,
                                    course_id,
                                    badge_id: "",
                                    badge_type:
                                        showcaseData && showcaseData.badge_type && !_isEmpty(showcaseData.badge_type) ? showcaseData.badge_type : "",
                                    score:
                                        showcaseData && showcaseData.score && (showcaseData.score === "pass" || showcaseData.score === "fail")
                                            ? showcaseData.score
                                            : "",
                                    project_title_1: this.getCourseShowcaseDataFromTrainee(trainee, course_id, "project_title_1"),
                                    project_url_1: this.getCourseShowcaseDataFromTrainee(trainee, course_id, "project_url_1"),
                                    project_feedback: this.getCourseShowcaseDataFromTrainee(trainee, course_id, "project_feedback"),
                                },
                            })
                        }
                    >
                        Review Now
                    </WarningButton>
                );
            case "edit":
                return (
                    <YellowButton
                        {...btnProps}
                        style={{
                            padding: "4px 8px",
                            marginLeft: "5px",
                            background:
                                showcaseData &&
                                showcaseData.score &&
                                showcaseData.score === "pass" &&
                                showcaseData.badge_id &&
                                !_isEmpty(showcaseData.badge_id)
                                    ? "#ffeb3b"
                                    : "#ffa000",
                            color:
                                showcaseData &&
                                showcaseData.score &&
                                showcaseData.score === "pass" &&
                                showcaseData.badge_id &&
                                !_isEmpty(showcaseData.badge_id)
                                    ? "#616161"
                                    : "#fafafa",
                        }}
                        onClick={() =>
                            this.setState({
                                openShowcaseModal: true,
                                modalType: "showcase_form",
                                traineeData: trainee || false,
                                modalData: {
                                    trainee_id: trainee.id,
                                    course_id,
                                    badge_id: showcaseData && showcaseData.badge_id && !_isEmpty(showcaseData.badge_id) ? showcaseData.badge_id : "",
                                    badge_type:
                                        showcaseData && showcaseData.badge_type && !_isEmpty(showcaseData.badge_type) ? showcaseData.badge_type : "",
                                    score:
                                        showcaseData && showcaseData.score && (showcaseData.score === "pass" || showcaseData.score === "fail")
                                            ? showcaseData.score
                                            : "",
                                    project_title_1: this.getCourseShowcaseDataFromTrainee(trainee, course_id, "project_title_1"),
                                    project_url_1: this.getCourseShowcaseDataFromTrainee(trainee, course_id, "project_url_1"),
                                    project_feedback: this.getCourseShowcaseDataFromTrainee(trainee, course_id, "project_feedback"),
                                },
                            })
                        }
                    >
                        {showcaseData &&
                        showcaseData.score &&
                        showcaseData.score === "pass" &&
                        showcaseData.badge_id &&
                        !_isEmpty(showcaseData.badge_id)
                            ? "Badge Approved"
                            : "Review Now"}
                    </YellowButton>
                );
            default:
                return (
                    <GreyButton
                        {...btnProps}
                        onClick={() =>
                            this.setState({
                                openShowcaseModal: true,
                                modalType: "showcase_form",
                                traineeData: trainee || false,
                                modalData: {
                                    trainee_id: trainee.id,
                                    course_id,
                                    badge_id: "",
                                    badge_type:
                                        showcaseData && showcaseData.badge_type && !_isEmpty(showcaseData.badge_type) ? showcaseData.badge_type : "",
                                    score:
                                        showcaseData && showcaseData.score && (showcaseData.score === "pass" || showcaseData.score === "fail")
                                            ? showcaseData.score
                                            : "",
                                    project_feedback: this.getCourseShowcaseDataFromTrainee(trainee, course_id, "project_feedback"),
                                },
                            })
                        }
                    >
                        Review
                    </GreyButton>
                );
            // return <GreyTag style={{ background: '#999', color: '#fff' }}>Review</GreyTag>;
        }
    };

    renderCourseColumn = ({ course, trainee }) => {
        const { badges, schedule, showcase, currentTrainees } = this.props;
        const coursebadges = getCourseBadges({ lms_course_id: course.id, badges, schedule });
        const course_access = getSelectedTraineeData({ key: "course_access", trainee, lms_course_id: course.id });
        const showcaseData = getSelectedTraineeData({ key: "showcase", trainee, lms_course_id: course.id });
        const feedbackForm = getFeedbackformFromTrainee({ trainee, lms_course_id: course.id, showcase });
        const courseShowcase = getTraineeCourseFromShowcase({ trainee, lms_course_id: course.id, showcase });
        const status = getShowcaseStatus({ trainee, lms_course_id: course.id, currentTrainees, showcase });
        let claimedTransferTrainee = false;
        if (isBadgeIssuedFromTransferedClass({ trainee, lms_course_id: course.id })) {
            claimedTransferTrainee = true;
        }
        return course_access ? (
            <CourseColumn>
                {!claimedTransferTrainee ? (
                    <>
                        {!isCompetitionModule(schedule) && (
                            <div>Open Day Date: {this.getCourseShowcaseDataFromTrainee(trainee, course.id, "showcase_date")}</div>
                        )}
                        <div style={{ margin: "5px 0 0 0" }}>
                            Feedback Form:{" "}
                            {feedbackForm && isArrayExists(feedbackForm) ? (
                                <i className="fa fa-check" style={{ color: green[700] }}></i>
                            ) : (
                                <i className="fa fa-times" style={{ color: red[700] }}></i>
                            )}
                        </div>
                        {!isCompetitionModule(schedule) && (
                            <>
                                <div style={{ margin: "5px 0 0 0" }}>
                                    Showcase Project:{" "}
                                    {courseShowcase && courseShowcase.project_desc && !_isEmpty(courseShowcase.project_desc) ? (
                                        <i className="fa fa-check" style={{ color: green[700] }}></i>
                                    ) : (
                                        <i className="fa fa-times" style={{ color: red[700] }}></i>
                                    )}
                                </div>
                                <div style={{ margin: "3px 0 -5px 0" }}>Showcase Project Evaluation:</div>
                                {this.renderScoringRadio(trainee, course, course_access ? false : true)}
                            </>
                        )}
                        {showcaseData &&
                        showcaseData.badge_type &&
                        !_isEmpty(showcaseData.badge_type) &&
                        showcaseData.badge_id &&
                        !_isEmpty(showcaseData.badge_id) ? (
                            <>
                                Badge Awarded:{" "}
                                {showcaseData && showcaseData.badge_id && !_isEmpty(showcaseData.badge_id) ? (
                                    <strong style={{ color: green[700], fontWeight: "700" }}>
                                        {getSelectedValue(coursebadges, showcaseData.badge_id, "id", "type_label", "---")}
                                    </strong>
                                ) : (
                                    "---"
                                )}
                            </>
                        ) : (
                            <>
                                Badge Nominated:{" "}
                                {showcaseData && showcaseData.badge_type && !_isEmpty(showcaseData.badge_type) ? (
                                    <strong style={{ color: green[700], fontWeight: "700" }}>
                                        {getSelectedValue(coursebadges, showcaseData.badge_type, "type", "type_label", "---")}
                                    </strong>
                                ) : (
                                    "---"
                                )}
                            </>
                        )}
                    </>
                ) : null}
                <div style={{ marginTop: "5px" }}>{this.renderShowcaseAction(trainee, course.id)}</div>
            </CourseColumn>
        ) : (
            <CourseColumn>---</CourseColumn>
        );
    };

    render() {
        const { authData, showcase, feedback_forms_fields, courses, course, trainee } = this.props;
        const { openFeedbackModal, openShowcaseModal, modalData, traineeData, modalType } = this.state;
        return (
            <>
                {this.renderCourseColumn({ course, trainee })}

                {openFeedbackModal ? (
                    <FeedbackFormAnswers
                        open={openFeedbackModal}
                        showcase={showcase || false}
                        trainee={traineeData || false}
                        courses={courses || false}
                        feedback_forms_fields={feedback_forms_fields || false}
                        course_id={modalData && modalData.course_id ? modalData.course_id : false}
                        onClose={() => this.setState({ openFeedbackModal: false })}
                    />
                ) : null}

                {openShowcaseModal ? (
                    <ModalView
                        open={openShowcaseModal}
                        title={this.renderShowcaseFormTitle()}
                        maxWidth="sm"
                        onClose={() => this.setState({ openShowcaseModal: false, modalData: false, modalType: false })}
                        disableBackdrop={true}
                        cancelLabel={"Cancel"}
                        noAction={false}
                        actionLabel={"Update"}
                        doAction={this.handleShowcaseUpdate}
                        contents={this.renderShowcaseForm()}
                    />
                ) : null}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
    };
};

export default compose(connect(mapStateToProps), withRouter)(ReviewShowcase);

/* eslint-disable */
import React, { useState, useEffect, useMemo } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _size from 'lodash/size';
import _isEqual from 'lodash/isEqual';
import _findIndex from 'lodash/findIndex';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
// import { NotionRenderer } from "react-notion-x";

import AppWrapper from '../../components/AppWrapper';
import Table from './table';
import FilterMenu from './filter_menu';
import LatestStats from './latest_stats';

import { isAdmin } from '../../helpers/auth';
import { cloneCollections } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/action';

import { getRegions } from '../../actions/m_regions';
import { toggleLoader } from '../../actions/global';

import { ButtonGroup, AInfoLink, InfoButton } from '../../styles/button';
import { SuccessTag } from '../../styles/tag';
import { WarningBox, InfoBox } from '../../styles/message';

// import { DEV_MODE } from '../../constants';

const ResultWrapper = styled.div`
    margin-top: 30px;
    padding-top: 15px;
    border-top: 1px solid #ddd;
`;

const doMassGeneration = async (regions, month) => {

    if ( regions && isArrayExists( regions ) ) {
        let cIndex = 0;
        while ( cIndex < regions.length ) {
            await callFunctionsAPI({
                    url: 'businessDBdataGenerate',
                    action: 'generate',
                    formData: {
                        month,
                        region: regions[cIndex]
                    }
                })
                .then(() => {
                    cIndex++;
                })
                .catch(error => {
                    let errorMessage = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unknown error occured. Please try again.' ) );
                    throw new Error( errorMessage );
                });
        }
    } // end - contacts

    return 'done';
}

const BusinessDashboard = ({
    authData,
    dispatch,
    regionsList,
}) => {
    const [ loaded, setLoaded ] = useState(false);
    const [ selectedData, setSelectedData ] = useState({});
    const [ dataLoaded, setDataLoaded ] = useState(false);
    const [ list, setList ] = useState([]);

    useEffect(() => {
        dispatch( getRegions() );
    },[]);

    useEffect(() => {
        if ( regionsList ) {
            setLoaded(true);
        }
    },[ regionsList ]);

    const year = useMemo(() => {
        return selectedData && selectedData.year ? selectedData.year : '2022';
    },[ selectedData ]);

    const region = useMemo(() => {
        return selectedData && selectedData.region ? selectedData.region : 'all';
    },[ selectedData ]);

    const handleGetData = ({ region = 'all', year = '2022' }) => {
        dispatch(toggleLoader(true));
        setDataLoaded(false);
        setSelectedData({ region, year });
        callFunctionsAPI({
            url: 'businessDBdata',
            action: 'get',
            formData: {
                region,
                year,
            }
        })
        .then(results => {
            dispatch(toggleLoader(false));
            setDataLoaded(true);
            setList(results);
        })
        .catch(error => {
            dispatch(toggleLoader(false));
            triggerErrorAlert(getAPIErrorMessage(error));
        });
    }

    const handleDataGeneration = (month) => {
        dispatch(toggleLoader(true));

        callFunctionsAPI({
            url: 'businessDBdataGenerate',
            action: 'generate',
            formData: {
                month,
                region
            }
        })
        .then(() => {
            return callFunctionsAPI({
                url: 'businessDBdata',
                action: 'get',
                formData: {
                    region,
                    year,
                }
            })
        })
        .then(results => {
            dispatch(toggleLoader(false));
            setList(results);
        })
        .catch(error => {
            dispatch(toggleLoader(false));
            triggerErrorAlert(getAPIErrorMessage(error));
        });
    }

    const handleMassDataGeneration = (regions,month) => {
        dispatch(toggleLoader(true,"Data generation process is on-going! Please do not close this window or click the Back button on your browser"));

        doMassGeneration(regions,month)
        .then(() => {
            return callFunctionsAPI({
                url: 'businessDBdata',
                action: 'get',
                formData: {
                    region,
                    year,
                }
            })
        })
        .then(results => {
            dispatch(toggleLoader(false));
            setList(results);
        })
        .catch(error => {
            dispatch(toggleLoader(false));
            triggerErrorAlert(getAPIErrorMessage(error));
        });
    }

    return <AppWrapper 
            subtitle="Summary"
            title="Business Dashboard"
            onLoad={( !( loaded ) ? true : false )}
            contents={ authData ? (
            <>
    
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                        <FilterMenu 
                            regions={regionsList}
                            onFilter={handleGetData} />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                    </Grid>
                </Grid>
    
                { dataLoaded && (
                <ResultWrapper>
                    <LatestStats
                        list={list}
                        year={year}
                        region={region}
                        regions={regionsList}
                        authData={authData}
                        onGenerate={handleDataGeneration}
                        onMassGeneration={handleMassDataGeneration} />
                    <Table
                        list={list}
                        year={year}
                        region={region}
                        authData={authData} />
                </ResultWrapper>
                ) }
    
            </>
            ) : null } />;

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        // courseAccessList: state.hq_summary && state.hq_summary.list ? state.hq_summary.list : null,
        // randNum: state.hq_summary && state.hq_summary.rand ? state.hq_summary.rand : null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(BusinessDashboard);
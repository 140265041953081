/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import QRCode from 'qrcode';
import Measure from 'react-measure';
import Dropzone from 'react-dropzone';
import RGL, { WidthProvider } from "react-grid-layout";
import WebFont from 'webfontloader';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import shortid from 'shortid';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';
import _forEach from 'lodash/forEach';
import _split from 'lodash/split';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _filter from 'lodash/filter';
import _isEqual from 'lodash/isEqual';

import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormRadio from '../../components/FormRadio';
import FormSelect from '../../components/FormSelect';
import ToolTipInfo from '../../components/ToolTipInfo';

import { FormBox } from '../../styles/form';
import { InverseButton, InfoButton, IndigoButton, ButtonGroup } from '../../styles/button';
import { ErrorBox } from '../../styles/message';

import { isAdmin, hasAccessRights } from '../../helpers/auth';
import { isArrayExists, isObjectExists } from '../../helpers/validation';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions, getSelectValues, getSelectedValue, replaceAll } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { isSchemaRequired } from '../../helpers/schemas';
import { uploadMediaToStorage } from '../../helpers/firebase';

import { partnerSchema } from '../../schemas/partner';

import { toggleLoader } from '../../actions/global';
import { appChangesMade } from '../../actions/misc';

import { TEMPLATE_OPTIONS_TYPE, YES_NO_OPTIONS, PLATFORM_CERT_URL, PLATFORM_BADGE_URL } from '../../constants';

const ReactGridLayout = WidthProvider(RGL);

const useStyles = theme => ({
    boxheading: {
        fontSize: '20px',
        fontWeight: "700",
        color: theme.palette.background,
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #ddd"
    }
});

const CanvasWrapper = styled.div`
    background: #ddd;
    padding: 30px 15px;
`;

const TemplateOptionsWrapper = styled.div`
    width: 100%;
    max-width: 842px;
    margin: 0 auto 15px;

    & > button {
        margin-right: 8px;
    }
`;

const CertificateWrapper = styled.div`
    width: 100%;
    max-width: 842px;
    margin: 0 auto;
    position: relative;
    box-shadow: 0 0 0 2px #212121;

    .certificate-grid {
        min-height: 595px;
    }

    ${props => {
        if ( props.backgroundImage && !_isEmpty( props.backgroundImage ) ) {
            return `
            background-image: url("${props.backgroundImage}");
            background-size: cover;
            background-position: center center;
            `;
        } else {
            return `background: #fff;`
        }
    }}
`;

const CertificateItem = styled.div`
    padding: 0;
    position: relative;
    cursor: move;
    overflow: hidden;
    box-shadow: inset 0 0 0 2px rgba(0,0,0,0);

    button.certificate-block-remove {
        opacity: 0;
        position: absolute;
        padding: 2px 6px 3px;
        top: 2px;
        right: 0px;
        border-radius: 0px;
    }

    button.certificate-block-edit {
        opacity: 0;
        position: absolute;
        padding: 2px 6px 3px;
        top: 2px;
        right: 20px;
        border-radius: 0px;
    }

    span.react-resizable-handle {
        display: inline-block;
        position: absolute;
        width: 20px;
        height: 20px;
        right: 2px;
        bottom: 2px;
        cursor: se-resize;
        opacity: 0;
    }

    &:hover {
        box-shadow: inset 0 0 0 2px #212121;

        button.certificate-block-remove,
        button.certificate-block-edit,
        span.react-resizable-handle { opacity: 1; }
    }
`;

const CertificateBlockShortcode = styled.div`
    margin: 20px 10px 0 10px;
    padding: 15px;
    border: 1px solid #bbb;
    background: #fafafa;

    h4 {
        margin: 0 0 10px;
        font-size: 1.5rem;
        font-weight: 700;
    }

    ul {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            display: block;
            margin-bottom: 5px;
            font-size: 1.25rem;
            color: #212121;
            strong { font-weight: 700; }
        }
    }
`;

const previewDataDefault = {
	name: 'Train-the-Trainer - Arduino Projects with Mobile Apps',
	user_name: 'MUHAMMAD HASSIF IMRAN BIN MUHAMMAD HARRIS IRWAN',
	type: 'novice'
};

class EditCertificate extends React.Component {

    state = {
        gridHeight: 0,
        openBlockModal: false,
        selectBlock: false,
        selectBlockType: false,
        previewMode: false,
        openPreviewModal: false,
        previewData: previewDataDefault,
        defaultQRCode: '',
    };

    componentDidMount() {
        this.getDefaultQRCode();
    }

    handlePreviewFormUpdate = (newValue,key) => {
        const { previewData } = this.state;
        var newData = cloneCollections( previewData );
        newData[key] = newValue;
        this.setState({ previewData: newData });
	}

    handleFormUpdate = ( newValue, key ) => {
        const { onFormUpdate, certificate } = this.props;
        let newData = ( certificate && !_isEmpty( certificate ) ? cloneCollections( certificate ) : {} );

        switch( key ) {
            default:
                newData[key] = newValue;
                break;
        } // end - key

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
    }

    handleEditBlock = () => {
        const { onFormUpdate, certificate } = this.props;
        const { selectBlock } = this.state;
		let newData = ( certificate && !_isEmpty( certificate ) ? cloneCollections( certificate ) : {} ),
			index = ( newData.template && isArrayExists( newData.template ) ? _findIndex( newData.template, { id: selectBlock.id } ) : -1 );

		if ( index >= 0 ) {
			newData.template[index] = selectBlock;
		}

		this.setState({ openBlockModal: false, selectBlock: false, selectBlockType: false });

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
    }

    handleBlockUpdate = ( newValue, key ) => {
        const { selectBlock } = this.state;
        let newData = ( selectBlock && !_isEmpty( selectBlock ) ? cloneCollections( selectBlock ) : {} );

		if ( !( newData.value && isObjectExists( newData.value ) ) )
			newData.value = {};

		if ( key == 'size' ) {
			newData.value[key] = parseInt( newValue, 10 );
		} else {
			newData.value[key] = newValue;
		}

        this.setState({ selectBlock: newData });
    }

    addImageToTemplate(certImage) {
		const { onFormUpdate, certificate } = this.props;
		let newData = ( certificate && !_isEmpty( certificate ) ? cloneCollections( certificate ) : {} );

		if ( !( newData.template && isArrayExists( newData.template ) ) )
			newData.template = [];

		if ( !( newData.template_layout && isArrayExists( newData.template_layout ) ) )
			newData.template_layout = [];

		// add item
		newData.template.push({
			id: certImage.id,
			type: 'image',
			path: certImage.path,
			value: certImage.url
		});

		newData.template_layout.push({
			i: certImage.id,
			x: 0,
			y: 0,
			w: 6,
			h: 3,
			minH: 2,
			minW: 1
		});

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
	}

	addBackgroundImageToTemplate(certImage) {
		const { onFormUpdate, certificate } = this.props;
		let newData = ( certificate && !_isEmpty( certificate ) ? cloneCollections( certificate ) : {} );

		if ( !( newData.template && isArrayExists( newData.template ) ) )
			newData.template = [];

		// add item
		let index = _find( newData.template, { type: 'background_image' } );
		if ( index >= 0 ) {
			newData.template[index] = {
				id: certImage.id,
				type: 'background_image',
				path: certImage.path,
				value: certImage.url
			};
		} else {
			newData.template.push({
				id: certImage.id,
				type: 'background_image',
				path: certImage.path,
				value: certImage.url
			});
		}

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
	}

	handleRemoveBgImage = (event) => {
		const { onFormUpdate, certificate } = this.props;
		let newData = ( certificate && !_isEmpty( certificate ) ? cloneCollections( certificate ) : {} );

		// remove item
		if ( certificate.template && isArrayExists( certificate.template ) ) {
			let pulled = _remove( newData.template, { type: 'background_image' } )
		}

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
	}

    handleFileDrop = drop_type => (files) => {
		const { certificate_id, certificate, authData, dispatch } = this.props;
        let file = false,
            fileType = 'png',
            error = false;

        _forEach( files, function(value) {
            file = value;
        });

        if ( file ) {
			let file_type = file.type || false;
			if ( file_type && ( file_type == 'image/png' || file_type == 'image/jpeg' ) ) {
				let file_size = file.size || false;
                // check for file size
				if ( file_size > ( 2*1000*1024 ) ) {
					error = "Your file size has exceeded allowable limit of 2MB.";
				}
			} else {
				error = "Invalid file type. We only accept image file in jpeg / png format.";
			} // end - file_type
        }

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            switch( file.type ) {
                case "image/png":
                    fileType = 'png';
                case "image/jpeg":
                    fileType = 'jpg';
                    break;
            }
            const file_id = shortid.generate();
            const path = 'certificates/' + certificate_id + '/images/'+file_id+'.' + fileType;
            dispatch(toggleLoader(true));
            uploadMediaToStorage(file, path, authData,true)
            .then((url) => {
                dispatch(toggleLoader(false));
                triggerSuccessAlert('Upload Done!');
                if ( drop_type && drop_type === 'background_image' ) {
                    this.addBackgroundImageToTemplate({
                        id: file_id,
                        path,
                        url
                    });
                } else if ( drop_type && drop_type === 'image_block' ) {
                    this.addImageToTemplate({
                        id: file_id,
                        path,
                        url
                    });
                }
            })
            .catch((error) => {
                dispatch(toggleLoader(false));
                triggerErrorAlert(error.message);
            });
        }
    }

    handleTemplateRemove = (item) => event => {
		const { onFormUpdate, certificate } = this.props;
		let newData = ( certificate && !_isEmpty( certificate ) ? cloneCollections( certificate ) : {} ),
			answer = confirm("Are you sure you want to remove this item?\n\nThis action cannot be reversed.");

		if ( answer ) {
			if ( newData.template && isArrayExists( newData.template ) )
				var pulled = _remove( newData.template, { id: item.id } );

			if ( newData.template_layout && isArrayExists( newData.template_layout ) )
				var pulled2 = _remove( newData.template_layout, { i: item.id } );

            // do update
            if ( onFormUpdate )
                onFormUpdate( newData );
		}
    }

    handleTemplateAdd = (type) => event => {
        const { onFormUpdate, certificate } = this.props;
		let newData = ( certificate && !_isEmpty( certificate ) ? cloneCollections( certificate ) : {} );

		if ( !( newData.template && isArrayExists( newData.template ) ) )
			newData.template = [];

		if ( !( newData.template_layout && isArrayExists( newData.template_layout ) ) )
			newData.template_layout = [];

		var item_id = shortid.generate(),
			value = '';

		switch( type ) {
			case 'h1': value = { size: 36, content: 'heading' }; break;
			case 'h2': value = { size: 28, content: 'heading' }; break;
			case 'h3': value = { size: 22, content: 'heading' }; break;
			case 'h4': value = { size: 18, content: 'heading' }; break;
			case 'paragraph': value = { size: 16, content: 'some text here' }; break;
			case 'qrcode': value = { size: 8, content: 'Validate this certificate’s authenticity at', codesize: 24 }; break;
		}

		// add item
		newData.template.push({
			id: item_id,
			type: type,
			value: value
		});

		newData.template_layout.push({
			i: item_id,
			x: 0,
			y: 0,
			w: ( type && type === 'qrcode' ? 5 : 10 ),
			h: ( type && type === 'qrcode' ? 3 : 2 ),
			minW: ( type && type === 'qrcode' ? 3 : 4 ),
			minH: ( type && type === 'qrcode' ? 1 : 1 )
		});

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
    }

    handleTemplateChange = (newTemplate) => {
        const { onFormUpdate, certificate } = this.props;
		let newData = ( certificate && !_isEmpty( certificate ) ? cloneCollections( certificate ) : {} );
		newData['template_layout'] = newTemplate;

        // do update
        if ( onFormUpdate )
            onFormUpdate( newData );
    }

 	filterContent(content) {
		const { previewData } = this.state;
		
		if ( content && !_isEmpty( content ) && previewData ) {

			// find course title
			if ( content.indexOf("__course_title__") >= 0 ) {
				content = replaceAll( content, "__course_title__", ( previewData.name || '' ) );
			}

			// find course date
			if ( content.indexOf("__course_date__") >= 0 ) {
				content = replaceAll( content, "__course_date__", ( previewData.date_of_course || '' ) );
			}

			// find trainee's name
			if ( content.indexOf("__cert_url__") >= 0 ) {
				content = replaceAll( content, "__cert_url__", PLATFORM_CERT_URL + shortid.generate() );
			}

            
			// find trainee's name
			if ( content.indexOf("__badge_url__") >= 0 ) {
				content = replaceAll( content, "__badge_url__", PLATFORM_BADGE_URL + shortid.generate() );
			}

            if ( content.indexOf("__program_title__") >= 0 ) {
				content = replaceAll( content, "__program_title__", ( badge.program_title || '' ) );
			}

			// find badge type
			if ( content.indexOf("__badge_type__") >= 0 ) {
				content = replaceAll( content, "__badge_type__", ( previewData.type || '' ) );
			}

			// find course title
			if ( content.indexOf("__trainee_name__") >= 0 ) {
				content = replaceAll( content, "__trainee_name__", ( previewData.user_name || '' ) );
			}

		} // end - content

		return content;
	}

    loadAllRequiredFonts() {
		const { certificate, fonts } = this.props;
		var loaded = [];
		if ( fonts && isArrayExists( fonts ) && certificate && certificate.template && isArrayExists( certificate.template ) ) {
			_forEach( certificate.template, item => {
				if ( item.type && ( item.type == 'h1' || item.type == 'h2' || item.type == 'h3' || item.type == 'h4' || item.type == 'paragraph' ) ) {
					if ( item.value && item.value.font && !_isEmpty( item.value.font ) ) {
						var selected = _find( fonts, { family: item.value.font } );
						if ( selected ) {
							var already_loaded = ( isArrayExists( loaded ) ? _find( loaded, { id: selected.family } ) : false );
							if ( !already_loaded ) {
								var families = [],
									font_label = selected.family;

								// get variants
								if ( selected.variants && isArrayExists( selected.variants ) ) {
									var count = 1;
									_forEach( selected.variants, variant => {
										font_label += ( count > 1 ? ',' : ':' ) + variant;
										count++;
									})
								} // end - selected.variants

								families.push(font_label);
								families.push(selected.category);
								WebFont.load({
									google: { families }
								});
								loaded.push({ id: selected.family });
							}
						} // end - selected
					} // end - item.value.font
				} // end - item.type
			});
		} // end - fonts
	}

	getBackgroundImage() {
		const { certificate } = this.props;
		let image = false;
		if ( certificate && certificate.template && isArrayExists( certificate.template ) ) {
			let block = _find( certificate.template, { type: 'background_image' } );
			if ( block && block.value && !_isEmpty( block.value ) ) {
				image = block.value;
			}
		} // end - certificate.template
		return image;
	}

	getInlineStyle( item, defaultFontSize) {
		const { fonts } = this.props;
		let style = { fontSize: ( item.value && item.value.size || defaultFontSize )+"px", textAlign: ( item.value && item.value.align || 'left' ) };

		if ( fonts && isArrayExists( fonts ) && item.value && item.value.font && !_isEmpty( item.value.font ) ) {
			let selected = _find( fonts, { family: item.value.font } );
			if ( selected ) {
				style['fontFamily'] = selected.family;
			}
		} // end - fonts

		return style;
	}

    getDefaultQRCode() {
		QRCode.toString('https://www.chumbaka.asia/', { width: 16 }, function(err, string) {
			if ( !err )
				this.setState({ defaultQRCode: string });
		}.bind(this));
	}

    renderPreviewModal = () => {
        const { previewData } = this.state;
        return (
		<div className="certificate-block-modal" style={{ paddingBottom: "30px" }}>
			<div className="certificate-block-form">
				<FormInput label="Course Title" value={( previewData && previewData.name || '' )} name="name" onChange={this.handlePreviewFormUpdate} />
				<FormInput label="Student's name" value={( previewData && previewData.user_name || '' )} name="user_name" onChange={this.handlePreviewFormUpdate} />
			</div>
		</div>
        )
    }

    renderBlockForm = () => {
        const { fonts } = this.props;
        const { selectBlock, selectBlockType } = this.state;
        return (
        <div>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormInput 
                        label="Content" 
                        name="content" 
                        value={( selectBlock.value && selectBlock.value.content ? selectBlock.value.content : '' )} 
                        multiline={( selectBlockType && selectBlockType == 'paragraph' ? true : false )}
                        rows={3}
                        onChange={this.handleBlockUpdate} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormInput 
                        label="Font Size" 
                        name="size" 
                        type="number"
                        value={( selectBlock.value && selectBlock.value.size ? selectBlock.value.size : '' )} 
                        onChange={this.handleBlockUpdate} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div style={{ paddingTop: '15px' }}>
                        <FormSelect 
                            label="Font" 
                            options={getSelectOptions({
                                list: ( fonts || false ),
                                options: [{ value: '', label: 'Select an Option' }],
                                keys: { value: 'family', label: 'family' },
                                sortBy: 'label'
                            })} 
                            value={( selectBlock.value && selectBlock.value.font || '' )} 
                            name="font" 
                            onChange={this.handleBlockUpdate} />
                    </div>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <div style={{ paddingTop: '15px' }}>
                        <FormSelect 
                            label="Text Align" 
                            options={[
                                { value: 'left', label: 'Left' },
                                { value: 'center', label: 'Center' },
                                { value: 'right', label: 'Right' }
                            ]} 
                            value={( selectBlock.value && selectBlock.value.align || 'left' )} 
                            name="align" 
                            onChange={this.handleBlockUpdate} />
                    </div>
                </Grid>
            </Grid>
            <CertificateBlockShortcode>
				<h4>Available Shortcodes:</h4>
				<ul>
                    <li>Program Title: <strong>__program_title__</strong></li>
					<li>Course title: <strong>__course_title__</strong></li>
					<li>Course date: <strong>__course_date__</strong></li>
					<li>Badge Type: <strong>__badge_type__</strong></li>
					<li>Student Name: <strong>__trainee_name__</strong></li>
                    <li>Badge URL: <strong>__badge_url__</strong></li>
                    <li>Cert URL: <strong>__cert_url__</strong></li>
				</ul>
			</CertificateBlockShortcode>
        </div>
        )
    }

    renderCertificateOptions = () => {
        const { previewMode } = this.state;
        let bg_image = this.getBackgroundImage();
        return (
        <Grid container spacing={2} style={{ margin: '10px auto 0 auto', maxWidth: '842px' }}>
            <Grid item xs={12} sm={6}>
                <ButtonGroup>
                    <IndigoButton size="small" onClick={() => this.setState({ openPreviewModal: true })}><i className={"fa " + ( previewMode ? "fa-refresh" : "fa-search-plus" )}></i>{ previewMode ? "Change Preview Data" : "Preview Mode"}</IndigoButton>
                    { previewMode ? <InverseButton size="small" onClick={() => this.setState({ openPreviewModal: false, 
					previewData: previewDataDefault,
					previewMode: false })}><i className="fa fa-sign-out fa-rotate-180"></i>Exit Preview Mode</InverseButton> : null }
                </ButtonGroup>
            </Grid>
            <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                { bg_image && !_isEmpty( bg_image ) ? (
                <>
                    <span style={{ paddingRight: '5px' }}><ToolTipInfo content={
                    <>
                        IMAGE SIZE: 842 PX * 595 PX<br />
                        Size of this canvas is 842 px * 595 px. So the uploaded image must comply to this. 
                    </>
                    } /></span>
                    <InverseButton size="small" onClick={this.handleRemoveBgImage}><i className="fa fa-remove"></i>Remove Background Image</InverseButton> 
                </> 
                ) : (
                <>
                    <span style={{ paddingRight: '5px' }}><ToolTipInfo content={
                    <>
                        IMAGE SIZE: 842 PX * 595 PX<br />
                        Size of this canvas is 842 px * 595 px. So the uploaded image must comply to this. 
                    </>
                    } /></span>
                    <Dropzone 
                        onDrop={this.handleFileDrop('background_image')} 
                        accept="image/jpeg,image/png">
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ style: {
                                display: "inline-block"
                            } })}>
                                <input {...getInputProps()} />
                                <InverseButton size="small"><i className="fa fa-picture-o"></i>Upload Background Image</InverseButton>
                            </div>
                        )}
                        </Dropzone>
                    {/* <div style={{ fontSize: "1.25rem", fontStyle: 'italic' }}>*Image size must be exactly 842 x 595 pixel.</div> */}
                </>
                )}
            </Grid>
        </Grid>
        )
    }

    renderTemplateOptions = () => {
        return (
        <TemplateOptionsWrapper>
            <InverseButton size="small" onClick={this.handleTemplateAdd('h1')}><i className="fa fa-header"></i>H1</InverseButton>
			<InverseButton size="small" onClick={this.handleTemplateAdd('h2')}><i className="fa fa-header"></i>H2</InverseButton>
			<InverseButton size="small" onClick={this.handleTemplateAdd('h3')}><i className="fa fa-header"></i>H3</InverseButton>
			<InverseButton size="small" onClick={this.handleTemplateAdd('h4')}><i className="fa fa-header"></i>H4</InverseButton>
			<InverseButton size="small" onClick={this.handleTemplateAdd('paragraph')}><i className="fa fa-paragraph"></i>Paragraph</InverseButton>
			<InverseButton size="small" onClick={this.handleTemplateAdd('qrcode')}><i className="fa fa-qrcode"></i>QR CODE</InverseButton>
            <Dropzone 
                onDrop={this.handleFileDrop('image_block')} 
                accept="image/jpeg,image/png">
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ style: {
                        display: "inline-block"
                    } })}>
                        <input {...getInputProps()} />
                        <InverseButton size="small"><i className="fa fa-picture-o"></i>image</InverseButton>
                    </div>
                )}
                </Dropzone>
        </TemplateOptionsWrapper>
        )
    }

    renderTemplateLayout = (item) => {
        const { previewMode } = this.state;
		switch( item.type ) {
			case 'image':
				return <div style={{ width: "100%", height: "100%", backgroundImage: "url("+item.value+")", backgroundRepeat: "no-repeat", backgroundSize: "100% auto" }} />
			case 'h1':
				return <h1 style={this.getInlineStyle(item,36)}>{( item.value && item.value.content ? ( previewMode ? this.filterContent( item.value.content ) : item.value.content ) : '' )}</h1>;
			case 'h2':
				return <h2 style={this.getInlineStyle(item,28)}>{( item.value && item.value.content ? ( previewMode ? this.filterContent( item.value.content ) : item.value.content ) : '' )}</h2>;
			case 'h3':
				return <h3 style={this.getInlineStyle(item,22)}>{( item.value && item.value.content ? ( previewMode ? this.filterContent( item.value.content ) : item.value.content ) : '' )}</h3>;
			case 'h4':
				return <h4 style={this.getInlineStyle(item,18)}>{( item.value && item.value.content ? ( previewMode ? this.filterContent( item.value.content ) : item.value.content ) : '' )}</h4>;
			case 'paragraph':
				var splitData = ( item.value && item.value.content && !_isEmpty( item.value.content ) ? _split( item.value.content, /(?:\r\n|\r|\n)/g ) : false ),
					count = 0; 
				return <div className="certificate-template-paragraph">{ splitData && isArrayExists( splitData ) ? _map( splitData, (line) => {
					count++;
					return <p key={count} style={this.getInlineStyle(item,16)}>{( line ? ( previewMode ? this.filterContent( line ) : line ) : '' )}</p>;
				}) : '' }</div>;
			case 'qrcode':
				return (
				<div className="certificate-template-qrcode">
					<div dangerouslySetInnerHTML={{__html: this.state.defaultQRCode }} />
				</div>
				);
		}
    }

    renderTemplateItem = (item) => {
		if ( item.type && item.type == 'background_image' ) {
			return <CertificateItem key={item.id} style={{ display: "none" }}></CertificateItem>;
		} else {
			return (
			<CertificateItem key={item.id}>
                <InverseButton className="certificate-block-remove" minWidth="0px" size="small" noIconMargin="yes" onClick={this.handleTemplateRemove(item)}><i className="fa fa-remove"></i></InverseButton>
				{ item.type && !_isEmpty( item.type ) ? this.renderTemplateLayout(item) : '' }
				{ item.type && !_isEmpty( item.type ) && ( item.type != 'image' && item.type != 'qrcode' ) ? <InfoButton className="certificate-block-edit" minWidth="0px" size="small" noIconMargin="yes" onClick={() => this.setState({ openBlockModal: true, selectBlock: item, selectBlockType: item.type })}><i className="fa fa-edit"></i></InfoButton> : '' }
			</CertificateItem>
			);
		} // end - item
    }

    renderCertificate = () => {
        const { dispatch, certificate } = this.props
        const { previewMode } = this.state;
        let bg_image = this.getBackgroundImage();
        return (
        <CertificateWrapper backgroundImage={( bg_image || false )}>
            <Measure
                bounds
                onResize={contentRect => {
                    this.setState({ gridHeight: ( contentRect.bounds && contentRect.bounds.height ? contentRect.bounds.height : 0 ) })
                }}>
                {({ measureRef }) => (
                <div ref={measureRef}>
                    <ReactGridLayout className={"certificate-grid" + ( previewMode ? ' preview-mode' : '' )}
                        layout={( certificate && certificate.template_layout ? certificate.template_layout : [] )} 
                        width={842} 
                        rowHeight={22}
                        cols={60}
                        items={20}
                        margin={[0, 0]}
                        isDraggable={true}
                        isResizable={true}
                        compactType={null}
                        preventCollision={true}
                        onLayoutChange={this.handleTemplateChange}
                        onDrag={() => { dispatch(appChangesMade()) }}
                        onResize={() => { dispatch(appChangesMade()) }} 
                        >
                        { certificate && certificate.template && isArrayExists( certificate.template ) ? _map( certificate.template, this.renderTemplateItem ) : null }
                    </ReactGridLayout>
                </div>
                )}
            </Measure>
        </CertificateWrapper>
        )
    }

    renderCertificateCanvas = () => {
        const { previewMode, gridHeight } = this.state;
        // load all required fonts
		this.loadAllRequiredFonts();
        return (
        <CanvasWrapper>
            {gridHeight && gridHeight > 600 ? <ErrorBox style={{ marginBottom: '20px' }}>{"The current template height is around "+parseInt(gridHeight)+"px. Please make sure the height didn't exceed 600px."}</ErrorBox> : '' }
			{ previewMode ? null : this.renderTemplateOptions()}
            {this.renderCertificate()}
            {this.renderCertificateOptions()}
        </CanvasWrapper>
        )
    }

    render = () => {
        const { classes, certificate } = this.props;
        const { openBlockModal, selectBlock, selectBlockType, previewMode, openPreviewModal, previewData } = this.state;
        return (
        <FormBox>

            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <FormRadio 
                        label={(
                        <>
                            <span style={{ paddingRight: '5px' }}><ToolTipInfo content={
                            <>
                                Disable to disallow anyone to use for future new badges. Disable for outdated certs.
                            </>
                            } /></span>
                            Status *
                        </>)} 
                        name="status" inline={true} value={( certificate.status || '' )} options={[
                        { value: 'active', label: 'Active' },
                        { value: 'disabled', label: 'Disabled' }
                    ]} onChange={this.handleFormUpdate} />
                </Grid>
                <Grid item xs={9}>
                    <FormInput label="Name (for your reference only) *" name="name" value={( certificate.name || '' )} onChange={this.handleFormUpdate} />
                </Grid>
                <Grid item xs={12}> 
                    {this.renderCertificateCanvas()}
                </Grid>
            </Grid>

            <ModalView 
                open={openBlockModal}
                title="Edit Block"
                onClose={() => this.setState({ openBlockModal: false, selectBlock: false, selectBlockType: false })}
                actionLabel="Update"
                doAction={this.handleEditBlock}
                contents={this.renderBlockForm()} />

			<ModalView 
				title="Preview Mode"
				open={openPreviewModal}
                actionLabel="Enter Preview Mode"
                cancelLabel="Close"
                disableBackdrop={true}
                doAction={() => this.setState({ previewMode: true, openPreviewModal: false })}
				autoScroll={true}
				onClose={() => this.setState({ openPreviewModal: false })}
				contents={this.renderPreviewModal()} />

        </FormBox>
        );
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(EditCertificate);
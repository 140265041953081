/* eslint-disable */
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
// import _isEmpty from 'lodash/isEmpty';
// import _merge from 'lodash/merge';
// import _size from 'lodash/size';
// import _isEqual from 'lodash/isEqual';

import AppWrapper from "../../components/AppWrapper";
import Table from "./table";

import { isAdmin, getUserRegionFilter } from "../../helpers/auth";
// import { cloneCollections } from '../../helpers/data';

import { getSchedules } from "../../actions/schedules";
import { getCoursesOptions } from "../../actions/courses";
import { getUsersOptions } from "../../actions/users";
import { getTrainingLocationsOptions } from "../../actions/training_locations";
import { getRegions } from "../../actions/m_regions";
// import { getMaintenance } from '../../actions/maintenance';
import { resetRedux } from "../../actions/misc";

class SchedulesPage extends React.Component {
    state = {
        // start_date: moment().utcOffset(8).subtract(6, 'months').startOf('date').valueOf(),
        start_date: moment().utcOffset(8).set({ year: 2021, month: 0, date: 1 }).startOf("date").valueOf(),
        end_date: moment().utcOffset(8).add(6, "months").endOf("date").valueOf(),
        filter_region: "all",
        filter_coordinator: "all",
        filter_venue: "all",
        randNum: false,
    };

    componentDidMount() {
        const { authData, regionsList, usersOptionsList, venueList, coursesList } = this.props;
        // get schedules
        this.props.dispatch(getSchedules(this.getQueryProps()));

        // get regions
        if (!regionsList) this.props.dispatch(getRegions());

        // get users options
        if (!usersOptionsList) this.props.dispatch(getUsersOptions({ region: getUserRegionFilter(authData) }));

        // get venue options
        if (!venueList) this.props.dispatch(getTrainingLocationsOptions({ region: getUserRegionFilter(authData) }));

        // get course options
        if (!coursesList) this.props.dispatch(getCoursesOptions({ region: getUserRegionFilter(authData) }));

        // set filter_region if not admin
        if (!isAdmin(authData)) {
            this.setState({ filter_region: getUserRegionFilter(authData) });
        } // end - authData
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { schedulesList, regionsList, usersOptionsList, venueList, coursesList, randNum } = this.props;
        if (schedulesList && regionsList && usersOptionsList && venueList && coursesList && randNum && randNum !== this.state.randNum) {
            this.setState({ randNum });
        } // end - randNum
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux("schedules"));
    }

    handleFilter = (props) => {
        const { authData } = this.props;
        const { start_date, end_date, filter_region, filter_coordinator, filter_venue } = props;
        // only trigger if start_date or end_date changed
        if (start_date !== this.state.start_date || end_date !== this.state.end_date) {
            this.props.dispatch(resetRedux("schedules"));
            setTimeout(() => {
                this.setState({ randNum: false, start_date, end_date, filter_region, filter_coordinator, filter_venue });
                this.props.dispatch(getSchedules({ start_date, end_date, region: getUserRegionFilter(authData) }));
            }, 300);
        } else {
            // otherwise just update state
            this.setState({ start_date, end_date, filter_region, filter_coordinator, filter_venue });
        } // end - start_date
    };

    getQueryProps = () => {
        const { authData } = this.props;
        const { start_date, end_date } = this.state;
        return { start_date, end_date, region: getUserRegionFilter(authData) };
    };

    renderContents() {
        const { start_date, end_date, filter_region, filter_coordinator, filter_venue, randNum } = this.state;
        const { authData, schedulesList, usersOptionsList, regionsList, venueList, coursesList } = this.props;
        return (
            <Table
                start_date={start_date || 0}
                end_date={end_date || 0}
                filter_region={filter_region || "all"}
                filter_coordinator={filter_coordinator || "all"}
                filter_venue={filter_venue || "all"}
                schedules={schedulesList}
                users={usersOptionsList}
                regions={regionsList}
                locations={venueList}
                courses={coursesList}
                authData={authData}
                randNum={randNum}
                onFilter={this.handleFilter}
            />
        );
    }

    render() {
        const { randNum } = this.state;
        const { authData } = this.props;
        return (
            <AppWrapper
                subtitle="1. Recruitment"
                title="Class Schedule"
                onLoad={!randNum ? true : false}
                contents={authData ? this.renderContents() : null}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        schedulesList: state.schedules && state.schedules.schedules ? state.schedules.schedules : null,
        randNum: state.schedules && state.schedules.rand ? state.schedules.rand : null,
        usersOptionsList: state.users && state.users.users_options ? state.users.users_options : false,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        venueList: state.training_locations && state.training_locations.locations_options ? state.training_locations.locations_options : false,
        coursesList: state.courses && state.courses.courses_options ? state.courses.courses_options : false,
    };
};

export default compose(connect(mapStateToProps), withRouter)(SchedulesPage);

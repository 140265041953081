/* eslint-disable */
import { api_env, firebase_dev_account } from "./env";
import {
    FIREBASE_ACCOUNT_ID,
    DBP_ACCOUNT_ID,
    FIREBASE_REGION,
    USE_EMULATOR,
    EMULATOR_PORT_CLOUD_FUNCTIONS,
    // EMULATOR_PORT_FIRESTORE,
    // EMULATOR_PORT_DATABASE
} from "./firebase";

//*incomplete - recheck don't need this anymore

export const DEV_MODE = api_env && api_env === "live" ? false : true;

// platform related
export const PLATFORM_NAME = "Chumbaka School Management System";
export const PLATFORM_NAME_LONG = "School Management System";
export const PLATFORM_ORG_NAME = "Chumbaka";
export const PLATFORM_COMPANY_NAME = "Chumbaka Sdn. Bhd.";
export const PLATFORM_ORG_LOGO = "/assets/images/logo.gif";
export const PLATFORM_PAYMENT_URL = "https://sms.chumbaka.asia/payment/";
export const PLATFORM_URL = "https://sms.chumbaka.asia/";
export const PLATFORM_WEBSITE_URL = "https://www.chumbaka.asia/";
export const PLATFORM_WEBSITE_COURSE_URL = "http://www.chumbaka.asia/workshop.php?course_code=";
export const PLATFORM_ID = "chumbakalms";
export const PLATFORM_EMAIL = "enquiry@chumbaka.asia";
export const PLATFORM_PHONE = "+604 640 7119";
export const PLATFORM_STORAGE_URL = "https://firebasestorage.googleapis.com/v0/b/" + FIREBASE_ACCOUNT_ID + ".appspot.com/o/";
export const PLATFORM_BACKPACK_URL = api_env && api_env === "live" ? "https://sms.chumbaka.asia/backpack/" : "http://localhost:3000/backpack/";
export const PLATFORM_COURSE_REGISTRATION_LINK =
    api_env == "dev" ? "http://localhost:3000/course_registration/" : "https://sms.chumbaka.asia/course_registration/";
export const PLATFORM_BADGE_URL = api_env && api_env === "live" ? "https://sms.chumbaka.asia/b/" : "http://localhost:3000/b/";
export const PLATFORM_CERT_URL = api_env && api_env === "live" ? "https://sms.chumbaka.asia/certificate/" : "http://localhost:3000/certificate/";
export const PLATFORM_DATA_API_URL =
    api_env && api_env === "live"
        ? "https://us-central1-chumbaka-dev-tms.cloudfunctions.net/data"
        : "http://localhost:5000/chumbaka-dev-tms/us-central1/data";

export const PLATFORM_ADMIN_DEFAULT_REGION = "HkRLPqoMX";
export const PLATFORM_CLASS_STATUS_PLAN = "By8VwRt7Q";
export const PLATFORM_CLASS_STATUS_CONFIRM = "ry1FqqoGX";
export const PLATFORM_PARTNER_DEFAULT_ROLE = "coordinator";

// API related
export const AUTH_TOKEN = PLATFORM_ID + "Token";

// LMS related
export const CANVAS_LMS_URL = "https://lms.chumbaka.asia/";

// ipay88 related
export const IPAY88_PAYMENT_REQUEST_URL =
    api_env && api_env === "live" ? "https://payment.ipay88.com.my/epayment/entry.asp" : "http://localhost/layers/psc/ipay88-fake-checkout/";

// firebase related
export const FIREBASE_API = "https://" + FIREBASE_ACCOUNT_ID + (firebase_dev_account ? "-default-rtdb" : "") + ".firebaseio.com/";
export const FIREBASE_CLOUD_API = DEV_MODE
    ? "http://localhost:" + (USE_EMULATOR ? EMULATOR_PORT_CLOUD_FUNCTIONS : "5000") + "/" + FIREBASE_ACCOUNT_ID + "/" + FIREBASE_REGION + "/"
    : "https://" + FIREBASE_REGION + "-" + FIREBASE_ACCOUNT_ID + ".cloudfunctions.net/";
export const FIRESTORE_DEFAULT_PERPAGE = api_env && api_env === "dev" ? 4 : 20;

// digital badges related
export const DBP_API = "https://" + FIREBASE_REGION + "-" + DBP_ACCOUNT_ID + ".cloudfunctions.net/";
//export const DBP_API = 'http://localhost:5000/'+DBP_ACCOUNT_ID+'/'+FIREBASE_REGION+'/';

// Limits
export const PROFILE_IMAGE_LIMIT = 1048576;
export const BADGE_IMAGE_LIMIT = 2 * 1000 * 1024;

// color skin constants
export const COLOR_SKIN_1 = "#D32F2F";
export const COLOR_SKIN_2 = "#F44336";
export const COLOR_SKIN_3 = "#448AFF";
export const COLOR_SKIN_4 = "#FFFFFF";
export const COLOR_SKIN_5 = "#212121";

// theme color constants
export const THEME_ERROR = "#F44336";

// responsive
export const HIDE_DRAWER_WIDTH = 1200;

// options
export const ENTRIES_OPTIONS = [
    { value: 5, label: "5" },
    { value: 20, label: "20" },
    { value: 40, label: "40" },
    { value: 60, label: "60" },
    { value: 80, label: "80" },
    { value: 100, label: "100" },
    { value: 200, label: "200" },
    { value: 500, label: "500" },
];

export const MONTH_OPTIONS = [
    { value: "0", label: "Jan", maxDate: 31 },
    { value: "1", label: "Feb", maxDate: 28 },
    { value: "2", label: "Mar", maxDate: 31 },
    { value: "3", label: "Apr", maxDate: 30 },
    { value: "4", label: "May", maxDate: 31 },
    { value: "5", label: "Jun", maxDate: 30 },
    { value: "6", label: "Jul", maxDate: 31 },
    { value: "7", label: "Aug", maxDate: 31 },
    { value: "8", label: "Sep", maxDate: 30 },
    { value: "9", label: "Oct", maxDate: 31 },
    { value: "10", label: "Nov", maxDate: 30 },
    { value: "11", label: "Dec", maxDate: 31 },
];

export const DAY_OPTIONS = [
    { value: "mon", label: "Monday", number: 1 },
    { value: "tue", label: "Tuesday", number: 2 },
    { value: "wed", label: "Wednesday", number: 3 },
    { value: "thu", label: "Thursday", number: 4 },
    { value: "fri", label: "Friday", number: 5 },
    { value: "sat", label: "Saturday", number: 6 },
    { value: "sun", label: "Sunday", number: 7 },
];

export const GUARDIAN_OPTIONS = [
    { value: "Parent", label: "Parent" },
    { value: "Guardian", label: "Guardian" },
    { value: "Teacher", label: "Teacher" },
];

export const GENDER_OPTIONS = [
    { value: "", label: "Select Gender" },
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
];
export const YES_NO_OPTIONS = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
];
export const PLACEHOLDER_OPTIONS = [
    { value: "option-1", label: "Option 1" },
    { value: "option-2", label: "Option 2" },
    { value: "option-3", label: "Option 3" },
];

export const ENROLLMENT_STATUS_OPTIONS = [
    { value: "registered", label: "Registered", disabled: "disabled", confirmation: true },
    { value: "trial", label: "Trial", confirmation: true },
    { value: "confirmed", label: "Confirmed", confirmation: true, showcase: true, course_access_2: true },
    { value: "active", label: "Active", course_access: true, showcase: true, course_access_2: true },
    { value: "inactivate", label: "Inactive", course_access: true, course_access_2: true },
    { value: "conclude", label: "Concluded", course_access: true, showcase: true, course_access_2: true },
    { value: "delete", label: "Deleted" },
];

export const PLATFORM_LEGACY_BADGE_TYPES = [
    { value: "novice", label: "Novice", disabled: "disabled" },
    { value: "developing", label: "Developing", disabled: "disabled" },
    { value: "proficient", label: "Proficient", disabled: "disabled" },
    { value: "distinguished", label: "Distinguished", disabled: "disabled" },
];

export const EMAIL_TEMPLATES_TYPES = [
    { id: "registration_receipt", label: "Registration Receipt", module_used: "Registration" },
    { id: "trial_invitation_email", label: "Trail Invitation Email", module_used: "Confirmation" },
    { id: "confirmation_email", label: "Confirmation Email", module_used: "Confirmation" },
    { id: "reminder", label: "Reminder", module_used: "Confirmation" },
    { id: "invoice", label: "Invoice", module_used: "Payment" },
    { id: "payment_reminder", label: "Payment Reminder", module_used: "Payment" },
    { id: "login_reminder", label: "Login Reminder", module_used: "Course Access" },
    { id: "withdrawal_notice", label: "Withdrawal Notice", module_used: "Course Access" },
    { id: "send_report", label: "Send Report", module_used: "Quarterly Report" },
    { id: "mentor_activation_email", label: "Mentor LMS Activation Email", module_used: "Mentors Database" },
    { id: "student_activation_email", label: "Student LMS Activation Email", module_used: "Student Database" },
    { id: "parent_activation_email", label: "Parent Observer Activation Email", module_used: "Student Database" },
    { id: "teacher_activation_email", label: "Teacher Observer Activation Email", module_used: "Student Database" },
    { id: "badge_issued_email", label: "Badge Issued Email", module_used: "Showcase" },
    { id: "badge_oneoff_issued_email", label: "Badge (One Off) Issued Email", module_used: "Showcase" },
    { id: "showcase_project_email", label: "Showcase Project Email", module_used: "Showcase" },
    { id: "showcase_outstanding_tasks_student", label: "Student Outstanding Task(s) Email", module_used: "Showcase" },
    { id: "showcase_outstanding_tasks_mentor", label: "Mentor Outstanding Task(s) Email", module_used: "Showcase" },
    { id: "showcase_badge_approved", label: "Badge Approved Email", module_used: "Showcase" },
];

export const TEMPLATE_OPTIONS_AGREE = {
    value: "Strongly Agree_|_Agree_|_Neutral_|_Disagree_|_Strongly Disagree",
    label: "Strongly Agree, Agree, Neutral, Disagree, Strongly Disagree",
};

export const TEMPLATE_OPTIONS_HIGH = {
    value: "Very High_|_High_|_Medium_|_Low_|_Very Low",
    label: "Very High, High, Medium, Low, Very Low",
};

export const TEMPLATE_OPTIONS_GOOD = {
    value: "Excellent_|_Good_|_Average_|_Poor_|_Very poor",
    label: "Excellent, Good, Average, Poor, Very poor",
};

export const TEMPLATE_OPTIONS_TYPE = [
    { label: "Short Answer", value: "input" },
    { label: "Paragraph", value: "textarea" },
    { label: "Multiple Choice", value: "radio" },
    { label: "Dropdown", value: "select" },
    { label: "Checkboxes", value: "checkboxes" },
    { label: "Section Title", value: "section_title" },
];

export const PARTNER_ROLES_OPTIONS = [
    { value: "coordinator", label: "Coordinator" },
    { value: "principal", label: "Principal" },
];

export const REGISTRATION_FORM_TYPES = [
    { id: "email", unique: true, fixed: true, default: true, category: "student", label: "Email", required: "yes", options: "", options_custom: "" },
    {
        id: "name",
        unique: true,
        fixed: true,
        default: true,
        category: "student",
        label: "Full Name",
        required: "yes",
        options: "",
        options_custom: "",
    },
    {
        id: "gender",
        unique: true,
        fixed: true,
        default: true,
        category: "student",
        label: "Gender",
        required: "yes",
        options: "gender",
        options_custom: "",
    },
    {
        id: "date_of_birth_string",
        unique: true,
        fixed: true,
        default: true,
        category: "student",
        label: "Date of Birth",
        required: "yes",
        options: "",
        options_custom: "",
    },
    { id: "org", unique: true, fixed: false, default: true, category: "student", label: "School", required: "no" },
    { id: "medical_condition", unique: true, fixed: false, default: true, category: "student", label: "Medical Condition", required: "no" },
    { id: "trial", unique: true, fixed: false, default: true, category: "student", label: "I want to come for trial", required: "no" },
    { id: "state", unique: true, fixed: false, default: false, category: "student", label: "State", required: "yes" },
    { id: "district", unique: true, fixed: false, default: false, category: "student", label: "District", required: "yes" },
    { id: "race", unique: true, fixed: false, default: false, category: "student", label: "Ethnicity", required: "yes" },
    {
        id: "iam",
        unique: true,
        fixed: false,
        default: false,
        category: "student",
        label: "I am a",
        required: "yes",
        options: "custom_options",
        options_custom: "Student,Teacher,University Volunteer",
    },

    { id: "input", unique: false, fixed: false, default: false, category: "general", label: "Short Answer" },
    { id: "textarea", unique: false, fixed: false, default: false, category: "general", label: "Paragraph" },
    { id: "radio", unique: false, fixed: false, default: false, category: "general", label: "Multiple Choice" },
    { id: "select", unique: false, fixed: false, default: false, category: "general", label: "Dropdown" },
];

// access rights
// list of access rights
export const ACCESS_RIGHTS = [
    { id: "cpr", label: "Product Profile (Read)", module_id: "cp", module: "Product Profile", section: "Setup" },
    { id: "cpm", label: "Product Profile (Update)", module_id: "cp", module: "Product Profile", section: "Setup" },
    { id: "cpw", label: "Product Profile (Create)", module_id: "cp", module: "Product Profile", section: "Setup" },
    { id: "cpd", label: "Product Profile (Delete)", module_id: "cp", module: "Product Profile", section: "Setup" },
    { id: "ppr", label: "Program Profile (Read)", module_id: "pp", module: "Program Profile", section: "Setup" },
    { id: "ppm", label: "Program Profile (Update)", module_id: "pp", module: "Program Profile", section: "Setup" },
    { id: "ppw", label: "Program Profile (Create)", module_id: "pp", module: "Program Profile", section: "Setup" },
    { id: "ppd", label: "Program Profile (Delete)", module_id: "pp", module: "Program Profile", section: "Setup" },
    { id: "ptr", label: "Partner (Read)", module_id: "pt", module: "Partner & Personnel Database", section: "Setup" },
    // { id: 'ptw', label: 'Partner (Create)', module_id: 'pt', module: 'Partner', section: 'Setup' },
    { id: "ptm", label: "Partner (Update)", module_id: "pt", module: "Partner & Personnel Database", section: "Setup" },
    { id: "tlr", label: "Training Venue (Read)", module_id: "tl", module: "Training Venue", section: "Setup" },
    { id: "tlm", label: "Training Venue (Update)", module_id: "tl", module: "Training Venue", section: "Setup" },
    { id: "tlw", label: "Training Venue (Create)", module_id: "tl", module: "Training Venue", section: "Setup" },
    { id: "tld", label: "Training Venue (Delete)", module_id: "tl", module: "Training Venue", section: "Setup" },
    { id: "mer", label: "Mentors Database (Read)", module_id: "me", module: "Mentors Database", section: "Setup" },
    { id: "mem", label: "Mentors Database (Update)", module_id: "me", module: "Mentors Database", section: "Setup" },
    { id: "mew", label: "Mentors Database (Create)", module_id: "me", module: "Mentors Database", section: "Setup" },
    { id: "med", label: "Mentors Database (Delete)", module_id: "me", module: "Mentors Database", section: "Setup" },

    { id: "scr", label: "Class Schedule (Read)", module_id: "sc", module: "Class Schedule", section: "Recruitment" },
    { id: "scm", label: "Class Schedule (Update)", module_id: "sc", module: "Class Schedule", section: "Recruitment" },
    { id: "scw", label: "Class Schedule (Create)", module_id: "sc", module: "Class Schedule", section: "Recruitment" },
    { id: "scd", label: "Class Schedule (Delete)", module_id: "sc", module: "Class Schedule", section: "Recruitment" },
    { id: "crr", label: "Class Registration (Read)", module_id: "cr", module: "Class Registration", section: "Recruitment" },
    { id: "crm", label: "Class Registration (Update)", module_id: "cr", module: "Class Registration", section: "Recruitment" },
    { id: "mdr", label: "Marketing Database (Read)", module_id: "md", module: "Marketing Database", section: "Recruitment" },
    { id: "mdm", label: "Marketing Database (Update)", module_id: "md", module: "Marketing Database", section: "Recruitment" },
    { id: "mdw", label: "Marketing Database (Create)", module_id: "md", module: "Marketing Database", section: "Recruitment" },
    { id: "mdd", label: "Marketing Database (Delete)", module_id: "md", module: "Marketing Database", section: "Recruitment" },

    { id: "cfr", label: "Confirmation (Read)", module_id: "cf", module: "Confirmation", section: "Enrolment" },
    { id: "cfm", label: "Confirmation (Update)", module_id: "cf", module: "Confirmation", section: "Enrolment" },

    { id: "car", label: "Course Access (Read)", module_id: "ca", module: "Course Access", section: "Delivery" },
    { id: "cam", label: "Course Access (Update)", module_id: "ca", module: "Course Access", section: "Delivery" },
    { id: "pyr", label: "Payment (Read)", module_id: "py", module: "Payment", section: "Delivery" },
    { id: "pym", label: "Payment (Update)", module_id: "py", module: "Payment", section: "Delivery" },
    { id: "arr", label: "Attendance & Reporting (Read)", module_id: "ar", module: "Attendance & Reporting", section: "Delivery" },
    { id: "arm", label: "Attendance & Reporting (Update)", module_id: "ar", module: "Attendance & Reporting", section: "Delivery" },
    { id: "sdr", label: "Student Database (Read)", module_id: "sd", module: "Student Database", section: "Delivery" },
    { id: "sdw", label: "Student Database (Update)", module_id: "sd", module: "Student Database", section: "Delivery" },

    { id: "shr", label: "Showcase (Read)", module_id: "sh", module: "Showcase", section: "Closing" },
    { id: "shm", label: "Showcase (Update)", module_id: "sh", module: "Showcase", section: "Closing" },
    { id: "qrr", label: "Quarterly Report (Read)", module_id: "qr", module: "Quarterly Report", section: "Closing" },
    { id: "qrm", label: "Quarterly Report (Update)", module_id: "qr", module: "Quarterly Report", section: "Closing" },
    { id: "obr", label: "Issue One-Off Badges (Read)", module_id: "ob", module: "Issue One-Off Badges", section: "Closing" },
    { id: "obw", label: "Issue One-Off Badges (Create)", module_id: "ob", module: "Issue One-Off Badges", section: "Closing" },

    { id: "rcr", label: "Report of Course Access (Read)", module_id: "rc", module: "Report of Course Access", section: "Summary" },
    { id: "rcm", label: "Report of Course Access (Update)", module_id: "rc", module: "Report of Course Access", section: "Summary" },

    { id: "bdr", label: "Business Dashboard (Read)", module_id: "bd", module: "Business Dashboard", section: "Summary" },
    { id: "bdm", label: "Business Dashboard (Update)", module_id: "bd", module: "Business Dashboard", section: "Summary" },

    { id: "mtr", label: "Maintenance (Read)", module_id: "mt", module: "Maintenance", section: "Maintenance" },
    { id: "mtw", label: "Maintenance (Write)", module_id: "mt", module: "Maintenance", section: "Maintenance" },
    { id: "mtd", label: "Maintenance (Delete)", module_id: "mt", module: "Maintenance", section: "Maintenance" },
];

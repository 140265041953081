/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _findIndex from 'lodash/findIndex';
import _find from 'lodash/find';

import TeamsTable from '../../modules/TeamsTable';

import AppWrapper from '../../components/AppWrapper';
import ErrorMessage from '../../components/ErrorMessage';
import DotsLoader from '../../components/DotsLoader';


// import { isAdminType } from '../../helpers/auth';
import { callFunctionsAPI, getAPIErrorMessage } from '../../helpers/action';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { compareString } from '../../helpers/data';

import { toggleLoader } from '../../actions/global';
import { isArrayExists, isObjectExists } from '../../helpers/validation';



// import { PLATFORM_NAME } from '../../constants';

// import theme from '../../theme';

const getScheduleTeams = (schedule_id) => {
    return new Promise((resolve,reject) => {
        callFunctionsAPI({ url: 'student', action: 'get_teams_by_schedule', formData: { schedule_id } })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });
    });
}

const Teams = ({
    authData,
    schedule,
    randNum,
    showcase,
    trainees,
    teams,
    onUpdate,
    onReset = () => {},
    dispatch
}) => {

    const handleShowcaseAction = (newTeam) => {
        let newTeamData = { ...newTeam },
            newTrainees = [ ...trainees ],
            newShowcase = { ...showcase };

        // find lms_course_id if not exists
        if ( !( newTeamData && newTeamData.lms_course_id && !_isEmpty( newTeamData.lms_course_id ) ) ) {
            if ( newShowcase.badges && newShowcase.badges[0] && isObjectExists( newShowcase.badges[0] ) && newShowcase.badges[0].references && newShowcase.badges[0].references.course_id ) {
                const selectedTrainee = _find( newTrainees, t => {
                    let valid = false;
                    if ( t.course_access && isArrayExists( t.course_access ) ) {
                        _forEach( t.course_access, c => {
                            if ( compareString( c.course_id, newShowcase.badges[0].references.course_id ) ) {
                                valid = true;
                            }
                        });
                    }
                    return valid;
                });
                if ( selectedTrainee && selectedTrainee.course_access && isArrayExists( selectedTrainee.course_access ) ) {
                    _forEach( selectedTrainee.course_access, c => {
                        if ( c.course_id && compareString( c.course_id, newShowcase.badges[0].references.course_id ) ) {
                            newTeamData.lms_course_id = c.id;
                        }
                    } );
                }
            }
        } // end - newTeamData

        const teamIndex = _findIndex(newShowcase.teams, { id: newTeamData.id });
        if ( teamIndex > -1 ) {
            newShowcase.teams[teamIndex] = { ...newTeamData };
        }

        if ( newTeamData.trainees && isArrayExists( newTeamData.trainees ) ) {
            _forEach( newTeamData.trainees, (trainee) => {
                const traineeIndex = _findIndex(newTrainees, { id: trainee.id });
                const showcaseIndex = ( traineeIndex >= 0 && newTrainees[traineeIndex].showcase ? _findIndex(newTrainees[traineeIndex].showcase, s => compareString( s.id, newTeamData.lms_course_id )) : -1 );

                if ( traineeIndex >= 0 ) {
                    if ( showcaseIndex >= 0  ) {
                        newTrainees[traineeIndex].showcase[showcaseIndex].badge_type = ( newTeamData.badge && newTeamData.badge.nominated_badge_type || '' );
                        newTrainees[traineeIndex].showcase[showcaseIndex].score = ( newTeamData.score || '' );

                        // update project title & desc
                        if ( newTeamData.projects && isArrayExists( newTeamData.projects ) ) {
                            _forEach( newTeamData.projects, (project) => {
                                newTrainees[traineeIndex].showcase[showcaseIndex]["project_title_"+project.id] = project.title;
                                newTrainees[traineeIndex].showcase[showcaseIndex]["project_url_"+project.id] = project.desc;
                            });
                        }

                    } else {
                        if ( !( newTrainees[traineeIndex].showcase && isArrayExists( newTrainees[traineeIndex].showcase ) ) )
                            newTrainees[traineeIndex].showcase = [];

                        let newShowcaseData = {
                            id: newTeamData.lms_course_id,
                            badge_id: '',
                            badge_type: ( newTeamData.badge && newTeamData.badge.nominated_badge_type || '' ),
                            project_feedback: '',
                            score: ( newTeamData.score || '' )
                        };

                        // update project title & desc
                        if ( newTeamData.projects && isArrayExists( newTeamData.projects ) ) {
                            _forEach( newTeamData.projects, (project) => {
                                newShowcaseData["project_title_"+project.id] = project.title;
                                newShowcaseData["project_url_"+project.id] = project.desc;
                            });
                        }

                        newTrainees[traineeIndex].showcase.push(newShowcaseData);
                    }
                }
            });
        } // end - trainees

        onUpdate(newTrainees, newShowcase);
    }

    const handleAction = (type,formData) => {
        let promises = [],
            successMessage = '';
        switch( type ) {
            case 'create':
                promises.push( callFunctionsAPI({ url: 'student', action: 'create_team', formData }) );
                successMessage = 'Team created successfully';
                break;
            case 'delete':
                promises.push( callFunctionsAPI({ url: 'student', action: 'delete_team', formData }) );
                successMessage = 'Team deleted';
                break;
            case 'edit':
            default:
                promises.push( callFunctionsAPI({ url: 'student', action: 'update_team', formData }) );
                successMessage = 'Team updated successfully';
                break;
        }

        dispatch( toggleLoader(true) );
        Promise.all(promises)
        .then( data => {
            onReset();
        })
        .catch(error => {
            dispatch( toggleLoader(false) );
            triggerErrorAlert( getAPIErrorMessage(error), 4000 );
        });
        
    }

    return <TeamsTable
        view={'admin'}
        adminView="showcase"
        teams={teams}
        schedule={schedule}
        showcase={showcase}
        trainees={trainees}
        // teachers={teachers}
        // mentors={mentors}
        onAction={handleAction}
        onShowcaseAction={handleShowcaseAction}
        />;

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        changesNotSaved: state.misc && state.misc.changes_made ? state.misc.changes_made : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(Teams);
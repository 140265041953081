/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import _isEmpty from 'lodash/isEmpty';
// import _merge from 'lodash/merge';
// import _size from 'lodash/size';
// import _isEqual from 'lodash/isEqual';

import AppWrapper from '../../components/AppWrapper';
import Table from './table';

// import { cloneCollections } from '../../helpers/data';

import { getProductsOptions } from '../../actions/courses';
import { getPrograms } from '../../actions/programs';
import { getMaintenance } from '../../actions/maintenance';
import { resetRedux } from '../../actions/misc';

class ProgramsPage extends React.Component {

    state = {
        // query: {
        //     searchterms: '',
        //     filterBy: 'all',
        //     sortBy: 'modified_on-desc',
        //     perPage: 20,
        //     page: 1
        // },
        randNum: false
    };

    componentDidMount() {
        const { courseTypeList, courseDurationList, pricePerPaxList, productsOptionsList } = this.props;
        // get programs
        this.props.dispatch(getPrograms());

        // get course type
        if ( !courseTypeList )
            this.props.dispatch(getMaintenance('course_type'));

        // get course duration
        if ( !courseDurationList )
            this.props.dispatch(getMaintenance('course_duration'));

        // get price per pax list
        if ( !pricePerPaxList )
            this.props.dispatch(getMaintenance('price_per_pax'));

        // get products
        if ( !productsOptionsList )
            this.props.dispatch(getProductsOptions());

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { programsList, courseTypeList, courseDurationList, pricePerPaxList, productsOptionsList, randNum } = this.props;
        if ( programsList && courseTypeList && courseDurationList && pricePerPaxList && productsOptionsList && randNum && randNum !== this.state.randNum ) {
            this.setState({ randNum });
        } // end - randNum
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('programs'));
    }

    handleRefresh = (props) => {
        // get programs
        this.setState({ randNum: false });
        this.props.dispatch(resetRedux('programs'));
        this.props.dispatch(getPrograms(props));
    }

    renderContents() {
        const { authData, programsList, courseTypeList, courseDurationList, pricePerPaxList, productsOptionsList } = this.props;
        return <Table
                programs={programsList}
                course_type={courseTypeList}
                course_duration={courseDurationList}
                price_per_pax={pricePerPaxList}
                products_options={productsOptionsList}
                authData={authData}
                onRefresh={this.handleRefresh} />;
    }

    render() {
        const { randNum } = this.state;
        const { programsList, authData } = this.props;
        return <AppWrapper 
                subtitle="Setup"
                title="Program Profile"
                onLoad={( !( randNum ) ? true : false )}
                contents={ authData ? this.renderContents() : null } />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        programsList: state.programs && state.programs.programs ? state.programs.programs : null,
        randNum: state.programs && state.programs.rand ? state.programs.rand : null,
        productsOptionsList: state.courses && state.courses.products_options ? state.courses.products_options : false,
        courseTypeList: state.maintenance && state.maintenance.course_type ? state.maintenance.course_type : null,
        courseDurationList: state.maintenance && state.maintenance.course_duration ? state.maintenance.course_duration : null,
        pricePerPaxList: state.maintenance && state.maintenance.price_per_pax ? state.maintenance.price_per_pax : null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ProgramsPage);
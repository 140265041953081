/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AppWrapper from '../../components/AppWrapper';
import Table from './table';

import { getUserRegionFilter } from '../../helpers/auth';
// import { cloneCollections } from '../../helpers/data';

import { getTrainingLocations } from '../../actions/training_locations';
import { getRegions } from '../../actions/m_regions';

class LocationsPage extends React.Component {

    state = {
        rand: false
    };

    componentDidMount() {
        const { authData, regionsList, rolesList } = this.props;

        // get lists
        this.props.dispatch(getTrainingLocations({ region: getUserRegionFilter(authData) }));

        // get regions
        if ( !regionsList ) {
            this.props.dispatch(getRegions());
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { locationsList, randNum } = this.props;

        // once all the data is loaded
        if ( locationsList && randNum && randNum !== this.state.rand )
            this.setState({ rand: randNum });
    }

    renderContents() {
        const { locationsList, regionsList, authData } = this.props;
        return (
        <Table 
            locations={locationsList} 
            regions={regionsList} 
            authData={authData} />
        );
    }

    render() {
        const { locationsList , regionsList, randNum } = this.props;
        return <AppWrapper 
                subtitle="Setup"
                title="Training Venues"
                onLoad={( !( locationsList && 
                    regionsList && 
                    randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        locationsList: state.training_locations && state.training_locations.locations ? state.training_locations.locations : null,
        randNum: state.training_locations && state.training_locations.rand ? state.training_locations.rand : null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(LocationsPage);
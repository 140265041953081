/* eslint-disable */
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_TRAINEES,
	GET_SCHEDULE,
	EDIT_SCHEDULE,
	UPDATE_CLASS_COURSE_ACCESS,
	// GLOBAL_ERROR_TRIGGER
} from '../types';

import { getAPIErrorMessage } from '../../helpers/action';
import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from '../global';
import { triggerErrorAlert, triggerSuccessAlert } from '../../helpers/alert';
import { isObjectExists } from '../../helpers/validation';
import { appChangesReset } from '../misc';

import { fb_updateClassCourseAccess, fb_updateClassMentors, fb_triggerCourseAccessEmail, fb_reupdateMentorsCourseAccess, fb_triggerStudentTransferClass } from './fb';
import { fb_getTrainees } from '../trainees/fb';
import { fb_getSchedule } from '../schedules/fb';


export const updateClassCourseAccess = (formData,props) => {
	return dispatch => {
		dispatch(toggleLoader(true,"Updating process is on-going! Please do not close this window or click the Back button on your browser"));

		fb_updateClassCourseAccess(formData)
		.then( results => {
			fb_getTrainees(props)
			.then(list => {
				// push new data
				dispatch({ type: GET_TRAINEES, payload: { list } });	
				// updated
				dispatch({ type: UPDATE_CLASS_COURSE_ACCESS });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Course Access Updated");
			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const updateClassMentors = (formData,props) => {
	return dispatch => {
		let apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_updateClassMentors(formData)
		.then( results => {
			fb_getSchedule(formData.schedule_id,props)
			.then(schedule => {
				// push new data
				dispatch({ type: GET_SCHEDULE, payload: { schedule } });	
				// updated
				dispatch({ type: EDIT_SCHEDULE });
				dispatch(toggleModalProcessing(false));
				triggerSuccessAlert("Mentor list Updated");
			})
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}



export const reupdateMentorsCourseAccess = (formData,props) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleLoader(true));

		fb_reupdateMentorsCourseAccess(formData)
		.then( results => {
			fb_getSchedule(formData.schedule_id,props)
			.then(schedule => {
				// push new data
				dispatch({ type: GET_SCHEDULE, payload: { schedule } });	
				// updated
				dispatch({ type: EDIT_SCHEDULE });
				dispatch(toggleLoader(false));
				triggerSuccessAlert("Mentors Course Access Updated");
			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const triggerCourseAccessEmail = (formData,props) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));
		dispatch(toggleLoader(true));

		fb_triggerCourseAccessEmail(formData)
		.then( results => {
			fb_getTrainees(props)
			.then(list => {
				// push new data
				dispatch({ type: GET_TRAINEES, payload: { list } });	
				// updated
				dispatch({ type: UPDATE_CLASS_COURSE_ACCESS });
				dispatch(toggleLoader(false));
				dispatch(toggleModalProcessing(false));
				triggerSuccessAlert("Email Sent! It might takes up to 5 minutes to reach students inbox.",4000);
			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			dispatch(toggleModalProcessing(false,apiNum));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const triggerStudentTransferClass = (formData,props) => {
	return dispatch => {
		dispatch(toggleLoader(true,"Transfering process is on-going! Please do not close this window or click the Back button on your browser"));

		fb_triggerStudentTransferClass(formData)
		.then( results => {
			fb_getTrainees(props)
			.then(list => {
				// push new data
				dispatch({ type: GET_TRAINEES, payload: { list } });	
				// updated
				dispatch({ type: UPDATE_CLASS_COURSE_ACCESS });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Class Transfer Successful");
			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}

export const triggerStudentUnenrol = (classData,emailData,props) => {
	return dispatch => {
		dispatch(toggleLoader(true,"Unenrol process is on-going! Please do not close this window or click the Back button on your browser"));

		fb_updateClassCourseAccess(classData)
		.then( results => {
			return ( emailData && isObjectExists( emailData ) ? fb_triggerCourseAccessEmail(emailData) : null );
		})
		.then( results => {
			fb_getTrainees(props)
			.then(list => {
				// push new data
				dispatch({ type: GET_TRAINEES, payload: { list } });	
				// updated
				dispatch({ type: UPDATE_CLASS_COURSE_ACCESS });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Unenrol Student Successful");
			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert(getAPIErrorMessage(error));
		});

	}
}
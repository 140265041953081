/* eslint-disable */
import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import Typography from '@material-ui/core/Typography';
import styled from "styled-components";
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
// import _merge from 'lodash/merge';
import _size from 'lodash/size';
import _isEqual from 'lodash/isEqual';
import _findIndex from 'lodash/findIndex';
import _toString from 'lodash/toString';

import EmailSender from './email';

import AppWrapper from '../../components/AppWrapper';
import SnackBarSave from '../../components/SnackBarSave';
import ScheduleSwitcher from '../../components/ScheduleSwitcher';
import Table from './table';
import CourseColumnsMenu from './columns_menu';
import MentorsList from './mentors_list';
import ClassFilterMenu from '../ClassRegistrationPage/filter_menu';

import { isAdmin, getUserRegionFilter } from '../../helpers/auth';
import { cloneCollections } from '../../helpers/data';
import { isArrayExists } from '../../helpers/validation';
import { getStudentEnrollmentStatus } from '../../helpers/trainees';

import { WarningBox, InfoBox } from '../../styles/message';
import { InfoButton } from '../../styles/button';
import { SuccessTag, InfoTag } from '../../styles/tag';

import { updateClassCourseAccess, triggerStudentTransferClass, triggerStudentUnenrol } from '../../actions/class_course_access';
import { getSchedule } from '../../actions/schedules';
import { getTrainees } from '../../actions/trainees';
import { getCoursesOptions } from '../../actions/courses';
import { getSchedulesOptions } from '../../actions/schedules';
import { getLMSCourses } from '../../actions/canvas_lms';
import { getRegions } from '../../actions/m_regions';
import { getMentors } from '../../actions/mentors';
import { getMaintenance } from '../../actions/maintenance';
import { resetRedux } from '../../actions/misc';

import { ButtonGroup, AInfoLink } from '../../styles/button';

import { ENROLLMENT_STATUS_OPTIONS } from '../../constants';

const TableWrapper = styled.div`
    margin-top: 30px;
    padding-top: 15px;
    border-top: 1px solid #ddd;
`;

class ClassCourseAccessPage extends React.Component {

    state = {
        trainees: false,
        currentSchedule: false,
        scheduleRand: false,
        traineesRand: false,
        courseColumns: [],
        unenrol_process: false,
        email_open: false,
        email_list: false,
        email_type: false,
        dataLoaded: false
    };

    componentDidMount() {
        const { authData, coursesList, regionsList, schedulesList, lmsCoursesList, mentorsList, emailTemplatesList, location } = this.props;

        // preload trainees
        if ( location && location.state && location.state.schedule_id && !_isEmpty( location.state.schedule_id ) ) {
            this.doFilter(location.state.schedule_id);
        }

        // get regions
        if ( !regionsList )
            this.props.dispatch(getRegions());

        // get course options
        // if ( !coursesList )
            this.props.dispatch(getCoursesOptions());

        // get schedule options
        if ( !schedulesList )
            this.props.dispatch(getSchedulesOptions({ region: getUserRegionFilter(authData) }));

        // get lms courses
        if ( !lmsCoursesList )
            this.props.dispatch(getLMSCourses());

        // get mentor list
        if ( !mentorsList )
            this.props.dispatch(getMentors({ region: getUserRegionFilter(authData) }));

        // get email templates
        if ( !emailTemplatesList )
            this.props.dispatch(getMaintenance('email_templates'));

        if ( regionsList && schedulesList && lmsCoursesList && mentorsList && emailTemplatesList ) {
            this.setState({ dataLoaded: true });
        } // end - randNum
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { coursesList, regionsList, schedulesList, lmsCoursesList, mentorsList, emailTemplatesList, traineesList, currentScheduleData, scheduleRand, traineesRand } = this.props;
        if ( regionsList && schedulesList && lmsCoursesList && mentorsList && emailTemplatesList && !this.state.dataLoaded ) {
            this.setState({ dataLoaded: true });
        } // end - randNum

        if ( currentScheduleData && coursesList && lmsCoursesList && mentorsList && emailTemplatesList && (
            ( scheduleRand && scheduleRand !== this.state.scheduleRand ) || 
            ( traineesRand && traineesRand !== this.state.traineesRand )
        ) ) {
            this.setState({ 
                scheduleRand,
                traineesRand, 
                courseColumns: this.getDefaultCourseColumns( currentScheduleData, coursesList, lmsCoursesList ), 
                trainees: ( traineesList && isArrayExists( traineesList ) ? cloneCollections( traineesList ) : [] ) 
            });
        } // end - scheduleRand
    }

    componentWillUnmount() {
        this.handleReset();
    }

    handleReset = () => {
        this.props.dispatch(resetRedux('trainees'));
        this.props.dispatch(resetRedux('schedule'));
        this.props.dispatch(resetRedux('classes'));
    }

    doFilter = (schedule_id) => {
        const { authData } = this.props;
        this.setState({ currentSchedule: schedule_id });
        this.props.dispatch(getTrainees({ schedule_id, enrollment_status: this.getEnrollmentStatusFilter() },true));
        this.props.dispatch(getSchedule(schedule_id,{ authData, getScheduleUsage: true }));
    }

    handleFilter = (schedule_id) => {
        this.doFilter(schedule_id);
    }

    handleSaveChanges = (event) => {
        const { trainees, currentSchedule } = this.state;
        event.preventDefault();
        let formData = { 
            schedule_id: currentSchedule, 
            trainees: this.compileTraineeFormData(trainees) 
        };
        this.props.dispatch( updateClassCourseAccess( formData, { 
            schedule_id: currentSchedule, 
            enrollment_status: this.getEnrollmentStatusFilter() 
        }) );
    }

    handleClassTransfer = (formData) => {
        const { currentSchedule } = this.state;
        this.props.dispatch( triggerStudentTransferClass( formData, { 
            schedule_id: currentSchedule, 
            enrollment_status: this.getEnrollmentStatusFilter() 
        }) );
    }

    handleEmailSent = (formData) => {
        const { trainees } = this.state; 
        this.setState({ email_open: false, email_list: false, email_type: false, unenrol_process: false });
        this.doStudentUnenrol(trainees,formData);
    }

    handleStudentsUnenrol = (newTrainees,email_list,withdrawal) => {
        if ( withdrawal ) {
            this.setState({ trainees: [ ...newTrainees ] });
            this.setState({ email_open: true, email_list, email_type: 'withdrawal_notice', unenrol_process: true });
        } else {
            this.doStudentUnenrol(newTrainees,false);
        }
    }

    doStudentUnenrol = ( trainees = [], emailData = false ) => {
        const { currentSchedule } = this.state;
        let traineesData = { 
            schedule_id: currentSchedule, 
            trainees: this.compileTraineeFormData(trainees) 
        };
        this.props.dispatch( triggerStudentUnenrol( 
            traineesData,
            emailData, 
            { 
                schedule_id: currentSchedule, 
                enrollment_status: this.getEnrollmentStatusFilter() 
            }) 
        );
    }

    compileTraineeFormData = (list) => {
        const { traineesList } = this.props;
        let data = [];
        // run through all the list to look for changes
        if ( list && isArrayExists( list ) && traineesList && isArrayExists( traineesList ) ) {
            _forEach( list, trainee => {
                let oriTrainee = _find( traineesList, { id: trainee.id } );
                if ( !_isEqual( trainee, oriTrainee ) ) {
                    let traineeData = {
                        id: trainee.id,
                        mentor: ( trainee.mentor || '' ),
                        course_access: this.compileCourseAccess(trainee)
                    };
                    if ( trainee.enrollment_status && trainee.enrollment_status === 'delete' ) {
                        traineeData.enrollment_status = 'delete';
                    }
                    data.push(traineeData);
                }
            });
        } // end - trainees
        return data;
    }

    compileCourseAccess = (trainee) => {
        let course_access = [];
        if ( trainee && trainee.course_access && isArrayExists( trainee.course_access ) ) {
            _forEach( trainee.course_access, item => {
                // make sure there is no duplicates 
                if ( !_find( course_access, { id: item.id, course_id: item.course_id, status: item.status } ) ) {
                    course_access.push( item );
                }
            });
        } // end - trainee.course_access
        return course_access;
    }

    getDefaultCourseColumns = (schedule,courses,lms_courses) => {
        let columns = [],
            enrollments_by_course = [];
        //*incomplete - don't need this
        // show all
        // if ( schedule.course_access && isArrayExists( schedule.course_access ) && lms_courses && isArrayExists( lms_courses ) && courses && isArrayExists( courses ) ) {
        //     _forEach( schedule.course_access, course => {
        //         let selected_course = ( course && course.id && !_isEmpty( course.id ) ? _find( courses, { id: course.id }) : false ),
        //             selected_lms_course = ( course && course.lms_course_id ? _find( lms_courses, c => _toString( c.id ) === _toString( course.lms_course_id ) ) : false );
        //         if ( selected_lms_course && selected_course ) {
        //             columns.push({ id: selected_lms_course.id, name: ( selected_course.name || '' ), course_code: ( selected_course.course_code || '' ) });
        //         } // end - selected_lms_course
        //     });
        // } // end - schedule.course_access
        // calculate most enrollments by courses
        if ( schedule && schedule.usage_enrollments && isArrayExists( schedule.usage_enrollments ) ) {
            _forEach( schedule.usage_enrollments, enrollment => {
                if ( enrollment.course_id ) {
                    let index = _findIndex( enrollments_by_course, c => _toString( c.id ) === _toString( enrollment.course_id ) );
                    if ( index >= 0 ) {
                        enrollments_by_course[index].count++;
                    } else {
                        enrollments_by_course.push({
                            id: enrollment.course_id,
                            count: 1
                        });
                    } // end - index
                } // end - enrollment.course_id
            });
            enrollments_by_course = _sortBy( enrollments_by_course, ['count'] );
            enrollments_by_course = _reverse( enrollments_by_course );
        } // end - schedule.usage_enrollments

        // show top 4 courses
        if ( schedule.course_access && isArrayExists( schedule.course_access ) && lms_courses && isArrayExists( lms_courses ) && isArrayExists( enrollments_by_course ) ) {
            for (let index = 0; index < 4; index++) {
                if ( enrollments_by_course[index] && enrollments_by_course[index].id ) {
                    let selected_course = ( courses && isArrayExists( courses ) ? _find( courses, c => c.lms_course_id && _toString(c.lms_course_id) === _toString(enrollments_by_course[index].id) ) : false ),
                        selected_product = _find( schedule.course_access, c => c.lms_course_id && _toString(c.lms_course_id) === _toString(enrollments_by_course[index].id) ),
                        selected_lms_course = _find( lms_courses, c => _toString( c.id ) === _toString( enrollments_by_course[index].id ) ),
                        course_name = ( selected_product && selected_product.name && !_isEmpty( selected_product.name ) ? selected_product.name : ( selected_course && selected_course.name || '' ) ),
                        course_code = ( selected_product && selected_product.course_code && !_isEmpty( selected_product.course_code ) ? selected_product.course_code : ( selected_course && selected_course.course_code || '' ) );

                    if ( selected_lms_course && selected_product ) {
                        columns.push({ id: selected_lms_course.id, name: course_name, course_code });
                    } // end - selected_lms_course
                }
            } // end - i
        } // end - schedule.course_access

        return columns;
    }

    getEnrollmentStatusFilter = () => {
        let statuses = [];
        ENROLLMENT_STATUS_OPTIONS.forEach(option => {
            if ( option.value && !_isEmpty( option.value ) && ( option.value !== 'delete' && option.value !== 'registered' && option.value !== 'trial' ) )
                statuses.push( option.value );
        });
        return statuses
    }

    getCurrentClassSize = (callback) => {
        const { traineesList } = this.props;
        const activeStudents = ( traineesList && isArrayExists( traineesList ) ? _filter( traineesList, (t) => callback(t) ? true : false ) : [] );
        return _size( activeStudents );
    }

    renderTable() {
        const { authData, coursesList, traineesList, lmsCoursesList, regionsList, mentorsList, schedulesList, currentScheduleData } = this.props;
        const { currentSchedule, courseColumns, trainees } = this.state;
        return <Table
                courseColumns={( courseColumns || false )}
                currentSchedule={( currentSchedule || false )}
                currentScheduleData={( currentScheduleData || false )}
                trainees={trainees}
                currentTrainees={traineesList}
                schedules={schedulesList}
                regions={regionsList}
                courses={coursesList}
                lms_courses={lmsCoursesList}
                mentors={mentorsList}
                authData={authData}
                onUpdate={(newTrainees) => this.setState({ trainees: newTrainees })}
                onClassTransferTrigger={this.handleClassTransfer}
                onStudentsUnenrol={this.handleStudentsUnenrol}
                onEmailSend={({ trainees, email_type }) => this.setState({ email_open: true, email_list: trainees, email_type })} />;
    }

    renderCurrentScheduleData = () => {
        const { authData, currentScheduleData } = this.props;
        return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <InfoBox style={{ marginBottom: '10px' }}>
                    <i className="fa fa-commenting-o"></i>Usage:<br />
                    Use this to enable your students the access to LMS. Upon granting access to your student, he/she will receive an email from {"Chumbaka LMS <lms@mail.chumbaka.asia>"} to complete the enrolment process. 
                </InfoBox>
                <WarningBox>
                    <i className="fa fa-lightbulb-o"></i>Note: Please ensure that information here is true & updated frequently. We will base our billing to you on this.
                </WarningBox>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5">Course Name: <InfoTag style={{ fontSize: '14px' }}>{( currentScheduleData && currentScheduleData.course_name ? currentScheduleData.course_name : '-' )}</InfoTag></Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5">Number of Registered Students: <SuccessTag style={{ fontSize: '16px' }}>{ currentScheduleData && currentScheduleData.usage_trainees_status ? _size( currentScheduleData.usage_trainees_status ) : 0 }</SuccessTag></Typography>
                <Typography variant="h5">Number of Confirmed Students: <SuccessTag style={{ fontSize: '16px' }}>{this.getCurrentClassSize((t) => t && t.enrollment_status && t.enrollment_status === 'confirmed' ? true : false)}</SuccessTag></Typography>
                <Typography variant="h5">Current Active Class Size: <SuccessTag style={{ fontSize: '16px' }}>{this.getCurrentClassSize((t) => t && getStudentEnrollmentStatus(t) === 'active' ? true : false)}</SuccessTag></Typography>
            </Grid>
        </Grid>
        )
    }

    renderContents() {
        const { authData, currentScheduleData, traineesList, emailTemplatesList, mentorsList, coursesList, lmsCoursesList, changesMade } = this.props;
        const { trainees, currentSchedule, scheduleRand, courseColumns, email_open, email_list, email_type, unenrol_process } = this.state;
        return (
        <>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <ClassFilterMenu 
                        currentSchedule={( currentSchedule || false )}
                        onFilter={this.handleFilter} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    { traineesList && scheduleRand ? this.renderCurrentScheduleData() : null }
                </Grid>
                { traineesList && scheduleRand ? (
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <MentorsList
                                authData={authData}
                                schedule={( currentScheduleData || false )}
                                mentors={mentorsList}
                                changesMade={changesMade} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <CourseColumnsMenu
                                courseColumns={( courseColumns || false )}
                                schedule={( currentScheduleData || false )}
                                courses={coursesList}
                                lms_courses={lmsCoursesList}
                                onUpdate={(newColumns) => this.setState({ courseColumns: newColumns })} />
                        </Grid>
                    </Grid>
                </Grid>
                ) : null }
            </Grid>

            { traineesList && scheduleRand ? (
            <TableWrapper>
                {this.renderTable()}
                { trainees && isArrayExists( trainees ) ? (
                <div style={{ textAlign: "center", marginTop: "45px" }}>
                    <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
                </div>
                ) : null }
            </TableWrapper>
            ) : null }

            <SnackBarSave onSave={this.handleSaveChanges} />

            <EmailSender
                open={email_open}
                trainees={( email_list || false )}
                email_type={( email_type || false )}
                schedule_id={( currentSchedule || false )}
                enrollment_status={this.getEnrollmentStatusFilter()}
                traineesList={traineesList}
                email_templates={emailTemplatesList}
                unenrol_process={unenrol_process}
                onEmailSent={this.handleEmailSent}
                onClose={() => this.setState({ email_open: false, email_list: false, email_type: false, unenrol_process: false })} />

        </>
        );
    }

    render() {
        const { dataLoaded, currentSchedule } = this.state;
        const { authData } = this.props;
        return <AppWrapper 
                subtitle="3. Delivery"
                title="Course Access"
                onLoad={( !( dataLoaded ) ? true : false )}
                customColumn={( currentSchedule && !_isEmpty( currentSchedule ) ? (
                    <ScheduleSwitcher
                        page="course_access"
                        schedule_id={currentSchedule}
                        authData={authData} />
                ) : null )}
                contents={ authData ? this.renderContents() : null } />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user ? state.auth.user : null,
        currentScheduleData: state.schedules && state.schedules.schedule ? state.schedules.schedule : null,
        scheduleRand: state.schedules && state.schedules.rand ? state.schedules.rand : null,
        traineesList: state.trainees && state.trainees.trainees ? state.trainees.trainees : null,
        traineesRand: state.trainees && state.trainees.rand ? state.trainees.rand : null,
        regionsList: state.maintenance && state.maintenance.regions ? state.maintenance.regions : false,
        coursesList: state.courses && state.courses.courses_options ? state.courses.courses_options : false,
        schedulesList: state.schedules && state.schedules.schedules_options ? state.schedules.schedules_options : false,
        lmsCoursesList: state.canvas_lms && state.canvas_lms.courses ? state.canvas_lms.courses : null,
        mentorsList: state.mentors && state.mentors.list ? state.mentors.list : null,
        emailTemplatesList: state.maintenance && state.maintenance.email_templates ? state.maintenance.email_templates : null,
        changesMade: state.misc && state.misc.changes_made ? state.misc.changes_made : null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ClassCourseAccessPage);
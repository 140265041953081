/* eslint-disable */
import firebase from "firebase/app";
import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _snakeCase from "lodash/snakeCase";
import _find from "lodash/find";

import { isAdmin } from "../../helpers/auth";
import { callFunctionsAPI, doPromise } from "../../helpers/action";
import { isArrayExists, validateEmail, isObjectExists } from "../../helpers/validation";
import { getSnapshotDataBySchema, getDocDataBySchema } from "../../helpers/schemas";

import { userStudentSchema } from "../../schemas/user_student";

/* helpers start */

/* helper end */

export const fb_getStudentBadges = (props = {}) => {
    return new Promise((resolve, reject) => {
        const { uid } = props;

        callFunctionsAPI({ url: "dbp", action: "get_user_badges", formData: { uid } })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_getStudentProfile = (uid) => {
    return new Promise((resolve, reject) => {
        let promises = [];
        promises.push(firebase.firestore().collection("users").doc(uid).get());

        Promise.all(promises)
            .then((results) => {
                let item = {};
                if (results && results[0]) {
                    item = getDocDataBySchema(userStudentSchema, results[0]);
                } // end - results[0]
                resolve(item);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_updateStudentProfile = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "student", action: "update_student_profile", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_deleteStudentBadge = (id) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "student", action: "delete_badge", formData: { id } })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_getBadgeDetails = (badge_id) => {
    return new Promise((resolve, reject) => {
        doPromise({ method: "POST", url: "dbp", params: [{ key: "action", value: "get_badge_details" }], formData: { badge_id } })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_getCertDetails = (badge_id) => {
    return new Promise((resolve, reject) => {
        doPromise({ method: "POST", url: "dbp", params: [{ key: "action", value: "get_cert_details" }], formData: { badge_id } })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_getUserBadgesByUID = (uid) => {
    return new Promise((resolve, reject) => {
        doPromise({ method: "POST", url: "dbp", params: [{ key: "action", value: "get_user_badges_by_uid" }], formData: { uid } })
            .then((res) => {
                console.log(res.data);
                resolve(res.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const connectWithBadgr = (formData) => {
    return new Promise((resolve, reject) => {
        callFunctionsAPI({ url: "student", action: "connect_badgr", formData })
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

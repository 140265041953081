/* eslint-disable */
import firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

import { callFunctionsAPI } from '../../helpers/action';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';

/* helpers start */
const triggerCourseAccessAPI = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'class', action: 'update_course_access', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

const doAsyncAPICall = async ({ schedule_id, trainees }) => {

    const list = [];
    const limits = 6;
    let count = 1,
        batch = [];

    if ( trainees && isArrayExists( trainees ) ) {
        _forEach( trainees, trainee => {
            batch.push(trainee);
            if ( count === limits ) {
                list.push(batch); // add to list
                // then reset
                count = 1; 
                batch = [];
            } else {
                count++;
            } // end - count
        });

        // if batch is not empty - add to list
        if ( batch && isArrayExists( batch ) )
            list.push( batch );

    } // end - trainees

    if ( list && isArrayExists( list ) ) {
        let cIndex = 0;
        while ( cIndex < list.length ) {
            await triggerCourseAccessAPI({ schedule_id, trainees: cloneCollections( list[cIndex]) })
                .then(() => {
                    cIndex++;
                })
                .catch(error => {
                    let errorMessage = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update course access. Please try again.' ) );
                    throw new Error( errorMessage );
                });
        }
    } // end - contacts

    return 'done';
}

/* helper end */

export const fb_updateClassCourseAccess = (formData) => {
    return new Promise((resolve,reject) => {

        doAsyncAPICall(formData)
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateClassMentors = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'class', action: 'update_class_mentors', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_reupdateMentorsCourseAccess = (formData) => {
    return new Promise((resolve,reject) => {

        setTimeout(() => {
            callFunctionsAPI({ url: 'class', action: 'reupdate_mentors_course_access', formData })
            .then(data => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            });
        },5000);

    })
}

export const fb_triggerCourseAccessEmail = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'class', action: 'trigger_course_access_email', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_triggerStudentTransferClass = (formData) => {
    return new Promise((resolve,reject) => {

        callFunctionsAPI({ url: 'student', action: 'student_transfer_class', formData })
        .then(data => {
            resolve(data);
        })
        .catch(error => {
            reject(error);
        });

    })
}